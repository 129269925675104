import React, { useState, createContext } from "react";

export const Authentication = createContext();

export const AuthProvider = (props) => {
  // let user = localStorage.getItem('userDetails');
  // if (user) {
  //   user = JSON.parse(user);
  // }
  const [userDetails, setUserDetails] = useState({});

  return (
    <Authentication.Provider value={{ userDetails, setUserDetails }}>
      {props.children}
    </Authentication.Provider>
  );
};
