import React, { useEffect, useState } from "react";

import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import { envDev } from "../App";
// import avatar from "../assets/avatar.png";
import {
  setUserDetails,
  userDetails,
} from "../utilities/RequireAuthentication";

const Profile = () => {
  // const { userDetails, setUserDetails } = useContext(Authentication);
  const [details, setDetails] = useState({
    username: userDetails.username,
    password: userDetails.pwd,
    company: userDetails.company,
    companyAliasName: userDetails.company_alias_name,
    email: userDetails.email,
    city: userDetails.city,
    id: userDetails.id,
    landNumber: userDetails.land_number,
    mobileNumber: userDetails.mobile_number,
    whatsappNumber: userDetails.whatsapp_Number,
    location: userDetails.location,
    guid: userDetails.guid,
  });

  const [imageName, setImageName] = useState("");
  const [imageUpload, setImageUpload] = useState({ imageFile: "" });
  const [selectedFileName, setSelectedFileName] = useState("No file chosen");

  const [clickEdit, setClickEdit] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);
  // const [pwd, setPwd] = useState(userDetails.pwd);
  const [showPassword, setShowPassword] = useState(false);

  const updateData = async () => {
    try {
      const response = await fetch(`${envDev}/updateusers/${userDetails.id}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(details),
      });
      const data = await response.json();
      console.log(data[0]);
      localStorage.setItem("userDetails", JSON.stringify(data[0]));
      setUserDetails(data[0]);
      setShowPassword(false);
      // setPwd(data[0].pwd);
    } catch (err) {
      console.error(err);
    }
  };

  const uploadBanner = async () => {
    const formData = new FormData();
    formData.append("imageFile", imageUpload.imageFile);

    try {
      const response = await fetch(
        `${envDev}/uploadBanner/${userDetails.id}-${
          userDetails.company
        }-${"banner"}`,
        {
          method: "POST",
          body: formData,
        }
      );

      const data = await response.json();

      if (response.status === 200) {
        alert("uploaded Sucessfully");
        getBanner();
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getBanner = async () => {
    const id = userDetails.id;
    try {
      const response = await fetch(`${envDev}/getbanner/${id}`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      const data = await response.json();
      console.log("imageName", imageName);
      setImageName(data[0].image_name);
    } catch (err) {
      console.error(err);
    }
  };

  const handleChange = (event) => {
    let { name, value } = event.target;
    setDetails((prev) => {
      return { ...prev, [name]: value };
    });
  };

  // const handleImageUpload = (event) => {
  //   const { name, type, files } = event.target;

  //   // Handle file input separately
  //   setImageUpload({
  //     ...imageUpload,
  //     [name]: files[0], // Use files[0] to get the uploaded file
  //   });

  //   // Update the selectedFileName state
  //   const newSelectedFileName = files[0] ? files[0].name : "No file chosen";
  //   setSelectedFileName(newSelectedFileName);
  //   if(imageUpload?.imageFile){
  //     console.log("imageUpload.imageFile",imageUpload.imageFile)
  //     uploadBanner();
  //   }
  // };

  const handleImageUpload = (e) => {
    e.preventDefault();
    const shouldUpload = window.confirm(
      "Are you sure you want to Upload Image ?"
    );

    if (shouldUpload) {
      // alert("Uploading...");
      uploadBanner();
    } else {
      alert("Canceled Upload Image.");
    }
    setImageUpload("");
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handlePersonal = () => {
    setClickEdit(!clickEdit);
    if (clickEdit) {
      updateData();
      setClickEdit(!clickEdit);
    }
  };

  const renderPassword = () => {
    if (showPassword) {
      return details.password;
    } else {
      return "•".repeat(details.password.length);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };

    getBanner();

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array means this effect runs once on mount

  return (
    <div>
      <AppBar position="static" className="app-bar">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            sx={{
              display: "flex",
              fontWeight: 600,
              fontSize: "20px",
              textDecoration: "none",
              marginBottom: "10px",
              marginLeft: "15px",
            }}
          >
            Your Profile
          </Typography>
        </Toolbar>
      </AppBar>
      {isMobile ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            height: "71.8vh",
          }}
        >
          <div
            style={{
              margin: "70px 0px",
              width: "85vw",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              alignItems: "center",
              border: "1px solid lightgrey",
            }}
          >
            <div>
              <div className="edit-image-container">
                <img
                  src={`${envDev}/images/${userDetails.company}/${imageName}`}
                  width="300px"
                  height="300px"
                  alt="loading"
                  className="image"
                />
                {/* <div class="edit-icon">
                  <EditIcon
                    className="icon"
                    // onClick={handlePersonal}
                    sx={{
                      cursor: "pointer",
                      width: "15px",
                      height: "auto",
                      marginLeft: "10px",
                    }}
                  />
                </div> */}
              </div>
              <div className="input">
                <input
                  type="file"
                  id="fileInput" // Give the input element an ID
                  style={{ display: "none" }} // Hide the input element
                  onChange={(event) => {
                    const { name, type, files } = event.target;

                    // Handle file input separately
                    setImageUpload({
                      ...imageUpload,
                      [name]: files[0], // Use files[0] to get the uploaded file
                    });

                    // Update the selectedFileName state
                    const newSelectedFileName = files[0]
                      ? files[0].name
                      : "No file chosen";
                    setSelectedFileName(newSelectedFileName);
                  }}
                  name="imageFile"
                />
                <label
                  htmlFor="fileInput"
                  style={{
                    cursor: "pointer",
                    padding: "10px",
                    border: "1px solid #ccc",
                    backgroundColor: "#f0f0f0",
                  }}
                >
                  Choose File
                </label>
                <p>{selectedFileName}</p>
                <button
                  // className="login_button button-primary"
                  onClick={handleImageUpload}
                  // disabled={!isFormValid} // Disable the button if the form is not valid
                >
                  Submit
                </button>
              </div>
            </div>
            <div style={{ width: "75vw" }}>
              <Typography
                className="border"
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                Account Details
              </Typography>
              <div
                style={{
                  paddingTop: "20px",
                  display: "flex",
                  width: "max-content",
                }}
              >
                <Typography
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    marginRight: "10px",
                  }}
                >
                  <span>Company :</span>
                  <span>Username :</span>
                  <span style={{ margin: "3px 0", width: "max-content" }}>
                    To customer:{" "}
                  </span>
                  <span>In-house: </span>
                  <span style={{ marginTop: "5px" }}>Password :</span>
                  <span style={{ marginTop: "5px" }}>Company Alias Name :</span>
                </Typography>
                <Typography
                  sx={{
                    marginBottom: "10px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <span style={{ width: "max-content" }}>
                    {" "}
                    {details.company}
                  </span>
                  <span style={{ width: "max-content" }}>
                    {" "}
                    {details.username}
                  </span>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={
                      window.location.origin + "/previewlist/" + details.guid
                    }
                  >
                    Click Here
                  </a>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={
                      window.location.origin + "/previewtable/" + details.guid
                    }
                  >
                    Click Here
                  </a>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {clickEdit ? (
                      <TextField
                        required
                        id="password"
                        variant="standard"
                        size="small"
                        sx={{ padding: "0px 10px 10px 0px", width: "200px" }}
                        onChange={handleChange}
                        name="password"
                        type={showPassword ? "text" : "password"}
                        value={details.password}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                              <SaveIcon
                                className="icon"
                                onClick={handlePersonal}
                                sx={{
                                  cursor: "pointer",
                                  width: "25px",
                                  height: "auto",
                                  marginLeft: "10px",
                                }}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    ) : (
                      // <span> {details.password}</span>
                      <span> {renderPassword()}</span>
                    )}
                    {clickEdit ? (
                      ""
                    ) : (
                      <div style={{ display: "flex" }}>
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                        <EditIcon
                          className="icon"
                          onClick={handlePersonal}
                          sx={{
                            cursor: "pointer",
                            width: "15px",
                            height: "auto",
                            marginLeft: "10px",
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {clickEdit ? (
                      <TextField
                        required
                        id="companyAliasName"
                        variant="standard"
                        size="small"
                        sx={{ padding: "0px 10px 10px 0px", width: "200px" }}
                        onChange={handleChange}
                        name="companyAliasName"
                        value={details.companyAliasName}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <SaveIcon
                                className="icon"
                                onClick={handlePersonal}
                                sx={{
                                  cursor: "pointer",
                                  width: "25px",
                                  height: "auto",
                                  marginLeft: "10px",
                                }}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    ) : (
                      <span> {details.companyAliasName}</span>
                      // <span> {renderPassword()}</span>
                    )}
                    {clickEdit ? (
                      ""
                    ) : (
                      <div style={{ display: "flex" }}>
                        <EditIcon
                          className="icon"
                          onClick={handlePersonal}
                          sx={{
                            cursor: "pointer",
                            width: "15px",
                            height: "auto",
                            marginLeft: "10px",
                          }}
                        />
                      </div>
                    )}
                  </div>
                </Typography>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            height: "76.8vh",
          }}
        >
          <Paper className="profile-style">
            <div>
              <div>
                {/* <img
                  src={`${envDev}/images/${userDetails.company}/${imageName}`}
                  // style={{ width: "50vw" }}
                  width="300px"
                  height="300px"
                  alt="loading"
                /> */}
                <div className="edit-image-container">
                  <img
                    src={`${envDev}/images/${userDetails.company}/${imageName}`}
                    width="300px"
                    height="300px"
                    alt="loading"
                    className="image"
                  />
                  {/* <div class="edit-icon">
                    <EditIcon
                      className="icon"
                      // onClick={handlePersonal}
                      sx={{
                        cursor: "pointer",
                        width: "30px",
                        height: "auto",
                        marginLeft: "10px",
                      }}
                    />
                  </div> */}
                </div>

                <div className="input">
                  {/* <label className="label">Image Upload</label> */}
                  <input
                    type="file"
                    id="fileInput" // Give the input element an ID
                    style={{ display: "none" }} // Hide the input element
                    onChange={(event) => {
                      const { name, type, files } = event.target;

                      // Handle file input separately
                      setImageUpload({
                        ...imageUpload,
                        [name]: files[0], // Use files[0] to get the uploaded file
                      });

                      // Update the selectedFileName state
                      const newSelectedFileName = files[0]
                        ? files[0].name
                        : "No file chosen";
                      setSelectedFileName(newSelectedFileName);
                    }}
                    name="imageFile"
                  />
                  <label
                    htmlFor="fileInput"
                    style={{
                      cursor: "pointer",
                      padding: "10px",
                      border: "1px solid #ccc",
                      backgroundColor: "#f0f0f0",
                    }}
                  >
                    Choose File
                  </label>
                  <p>{selectedFileName}</p>
                  <button
                    // className="login_button button-primary"
                    onClick={handleImageUpload}
                    // disabled={!isFormValid} // Disable the button if the form is not valid
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
            <div style={{ width: "400px" }}>
              <Typography
                className="border"
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                Account Details
              </Typography>
              <div
                style={{
                  paddingTop: "20px",
                  display: "flex",
                  width: "max-content",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    marginRight: "10px",
                  }}
                >
                  <span>Company:</span>
                  <span>Username:</span>
                  <span style={{ margin: "4px 0" }}>To customer: </span>
                  <span>In-house: </span>
                  <span style={{ marginTop: "10px" }}>Password:</span>
                  <span  style={{ marginTop: "10px" }}>Company Alias Name: </span>

                </div>
                <Typography
                  sx={{
                    marginBottom: "10px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <span style={{ width: "max-content" }}>
                    {" "}
                    {userDetails.company}
                  </span>
                  <span style={{ width: "max-content" }}>
                    {" "}
                    {userDetails.username}
                  </span>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={
                      window.location.origin + "/previewlist/" + details.guid
                    }
                  >
                    Click Here
                  </a>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={
                      window.location.origin + "/previewtable/" + details.guid
                    }
                  >
                    Click Here
                  </a>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {clickEdit ? (
                      <TextField
                        required
                        id="password"
                        variant="standard"
                        size="small"
                        sx={{ padding: "0px 10px 10px 0px" }}
                        onChange={handleChange}
                        name="password"
                        type={showPassword ? "text" : "password"}
                        value={details.password}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                              <SaveIcon
                                className="icon"
                                onClick={handlePersonal}
                                sx={{
                                  cursor: "pointer",
                                  width: "25px",
                                  height: "auto",
                                  marginLeft: "10px",
                                }}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    ) : (
                      // <span> {details.password}</span>
                      <span> {renderPassword()}</span>
                    )}
                    {clickEdit ? (
                      ""
                    ) : (
                      <div style={{ display: "flex" }}>
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                        <EditIcon
                          className="icon"
                          onClick={handlePersonal}
                          sx={{
                            cursor: "pointer",
                            width: "15px",
                            height: "auto",
                            marginLeft: "10px",
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {clickEdit ? (
                      <TextField
                        required
                        id="companyAliasName"
                        variant="standard"
                        size="small"
                        sx={{ padding: "0px 10px 10px 0px" }}
                        onChange={handleChange}
                        name="companyAliasName"
                        value={details.companyAliasName}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              
                              <SaveIcon
                                className="icon"
                                onClick={handlePersonal}
                                sx={{
                                  cursor: "pointer",
                                  width: "25px",
                                  height: "auto",
                                  marginLeft: "10px",
                                }}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    ) : (
                      <span> {details.companyAliasName}</span>
                      // <span> {renderPassword()}</span>
                    )}
                    {clickEdit ? (
                      ""
                    ) : (
                      <div style={{ display: "flex" }}>
                        
                        <EditIcon
                          className="icon"
                          onClick={handlePersonal}
                          sx={{
                            cursor: "pointer",
                            width: "15px",
                            height: "auto",
                            marginLeft: "10px",
                          }}
                        />
                      </div>
                    )}
                  </div>
                </Typography>
              </div>
              <Typography
                className="border"
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                Personal Details
              </Typography>
              <div style={{ paddingTop: "20px", display: "flex", gap: "4%" }}>
                <Typography sx={{ display: "flex", flexDirection: "column" }}>
                  <span>Mobile Number :</span>
                  <span>LandLine Number :</span>
                  <span>whatsApp Number :</span>
                  <span>Email ID:</span>
                  <span>City :</span>
                </Typography>
                <Typography
                  sx={{
                    marginBottom: "10px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <span> {userDetails.mobile_number}</span>
                  <span> {userDetails.land_number}</span>
                  <span> {userDetails.whatsapp_number}</span>
                  <span> {userDetails.email}</span>
                  <span>{userDetails.city}</span>
                </Typography>
              </div>
            </div>
          </Paper>
        </div>
      )}
    </div>
  );
};

export default Profile;
