import React, { useState, useEffect } from "react";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { envDev } from "../App";

function CheckEmail(props) {
  const [email, setEmail] = useState("");
  const [invalid, setInvalid] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `${envDev}/users/Login/forgotpwd/${email}`,
        {
          method: "GET",
        }
      );
      const data = await response.json();

      if (data.rowCount !== 0) {
        props.onCheckEmail(email);
        props.onUser();
      } else {
        setInvalid((current) => !current);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {}, [invalid]);

  return (
    <div style={{ marginTop: "30px" }}>
      <form onSubmit={handleSubmit}>
        <div className="input">
          <label className="label">Email Id </label>
          <TextField
            required
            id="filled-basic"
            type="email"
            sx={{ width: 250 }}
            name="email"
            variant="filled"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div>{invalid && <p style={{ color: "red",textAlign:"center" }}>Invalid User</p>}</div>
        <div className="btn">
          <Button
            className="button-primary"
            variant="contained"
            style={{ marginLeft: "150px", marginTop: "20px" }}
            type="submit"
          >
            Validate User
          </Button>
        </div>
      </form>
    </div>
  );
}

export default CheckEmail;
