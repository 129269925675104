import { useState, useEffect, useContext } from "react";

import { Authentication } from "../utilities/Authentication";
import frontpage from "../assets/0SM.jpg";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import { SketchPicker } from "react-color";
import Slider from "@mui/material/Slider";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { Box, Button } from "@mui/material";
import Carousel from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Marquee from "react-fast-marquee";
import { userDetails } from "../utilities/RequireAuthentication";
import { envDev } from "../App";

function Home() {
  // const { userDetails, setUserDetails } = useContext(Authentication);

  const [category, setCategory] = useState([]);
  const [selectedCategoryName, setSelectedCategoryName] = useState("");

  const initialColor = {
    backColor: false,
    textColor: false,
  };

  const [setting, setSetting] = useState({});
  const [categoryImages, setcategoryImages] = useState([]);
  const [displayColorPicker, setDisplayColorPicker] = useState(initialColor);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

  const handleInputChange = (event) => {
    const { name, value, checked } = event.target;

    if (name === "category") {
      // Handle category selection
      const category_id = getCategoryIDFromCategoryName(value);
      setcategoryImages(setting[category_id]);

      setSetting((prevSetting) => ({
        ...prevSetting,
        selected_category: category_id,
      }));
    } else if (name === "show_text") {
      // Handle checkbox for show_text
      setSetting((prevSetting) => ({
        ...prevSetting,
        show_text: checked ? true : false,
      }));
    } else if (name === "transparent") {
      // Handle checkbox for show_text
      setSetting((prevSetting) => ({
        ...prevSetting,
        transparent: checked ? true : false,
      }));
    } else {
      setSetting((prevSetting) => ({
        ...prevSetting,
        [name]: value,
      }));
    }
  };

  const getCategoryIDFromCategoryName = (selectedCategory) => {
    const selectedCategoryObj = category.find(
      (option) => option.display_category === selectedCategory
    );

    return selectedCategoryObj ? selectedCategoryObj.category_id : null;
  };

  const handleBackColorClick = () => {
    setDisplayColorPicker({
      ...displayColorPicker,
      backColor: true,
    });
  };

  const handleTextColorClick = () => {
    setDisplayColorPicker({
      ...displayColorPicker,
      textColor: true,
    });
  };

  const handleBackColorClose = () => {
    setDisplayColorPicker({
      ...displayColorPicker,
      backColor: false,
    });
  };

  const handleTextColorClose = () => {
    setDisplayColorPicker({
      ...displayColorPicker,
      textColor: false,
    });
  };

  const handleBackColorChange = (newColor) => {
    setSetting((prevSetting) => ({
      ...prevSetting,
      back_colour: newColor.hex,
    }));
  };

  const handleTextColorChange = (newColor) => {
    setSetting((prevSetting) => ({
      ...prevSetting,
      text_colour: newColor.hex,
    }));
  };

  const handleSpeedChange = (event, newValue) => {
    setSetting((prevSetting) => ({
      ...prevSetting,
      speed: newValue,
    }));
  };

  const handleAnimationChange = (event, newValue) => {
    setSetting((prevSetting) => ({
      ...prevSetting,
      animation: newValue,
    }));
  };

  const getCategory = async () => {
    const id = userDetails.id;
    try {
      const response = await fetch(`${envDev}/categories/${id}`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });

      const data = await response.json();
      setCategory(data);
    } catch (err) {
      console.log(err.message);
    }
  };

  const getSetting = async () => {
    const id = userDetails.id;
    try {
      const response = await fetch(`${envDev}/getsetting/${id}`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });

      const data = await response.json();

      
      const d = data[0];
      const transformedData = {
        description: d.description,
        show_text: d.show_text,
        back_colour: d.back_colour,
        text_colour: d.text_colour,
        animation: d.animation,
        transparent: d.transparent,
        speed: d.speed,
        show_image: d.show_image,
        selected_category: d.selected_category,
      };
      data.forEach((element) => {
        if (!transformedData[element.category_id]) {
          transformedData[element.category_id] = [];
        }
        if (element.show_image) {
          transformedData[element.category_id].push(element.image_name);
        }
        // image_names: d.show_image ? [curr.image_name] : [],
      });


      setSetting(transformedData);
      if (transformedData[d.selected_category]) {
        setcategoryImages(transformedData[d.selected_category]);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  const updateSetting = async () => {
    try {
      const response = await fetch(
        `${envDev}/updatesetting/${userDetails.id}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(setting),
        }
      );

      const result = await response.json();
      if (result.success) {
        // Update was successful
        alert(result.message);
        getSetting();
      } else {
        // Update was not successful
        alert(result.message);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleSubmit = () => {
    updateSetting();
  };

  useEffect(() => {
    getCategory();
    getSetting();
  }, []);

  useEffect(() => {
    // Check if category is not empty before trying to set CategoryName
    if (category.length > 0 && setting.selected_category) {
      const CategoryName = category.find(
        (item) => item.category_id === setting.selected_category
      );
      if (CategoryName) {
        setSelectedCategoryName(CategoryName.display_category);
      }
    }
  }, [category, setting.selected_category]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array means this effect runs once on mount

  return (
    <div>
      <AppBar position="static" className="app-bar">
        <form>
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              sx={{
                display: "flex",
                fontWeight: 600,
                fontSize: "20px",
                textDecoration: "none",
                marginBottom: "10px",
                marginLeft: "15px",
              }}
            >
              Home
            </Typography>
          </Toolbar>
        </form>
      </AppBar>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "10px",
          height: "76.3vh",
        }}
      >
        <Paper className="home-style">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <h2>Current Category</h2>
            <Box className="home-box">
              {categoryImages?.length ? (
                <Carousel
                  autoPlay
                  interval={1000} // Adjust the interval as needed (in milliseconds)
                  infiniteLoop
                  arrows={true}
                  autoplay={true}
                  // dots={true}
                  fade={setting.animation === "fade"}
                  animation="slide"
                >
                  {categoryImages?.length > 0 ? (
                    categoryImages.map((data) => (
                      <div key={data}>
                        <img
                          className="image-size"
                          src={`${envDev}/images/${userDetails.company}/${data}`}
                          alt={`Image ${data}`}
                        />
                      </div>
                    ))
                  ) : (
                    <Typography
                      component="div"
                      sx={{ position: "absolute", top: "50%", left: "45%" }}
                    >
                      -- No Products Found --
                    </Typography>
                  )}
                </Carousel>
              ) : (
                <img className="image-size" alt="loading" src={frontpage}></img>
              )}

              {!setting.show_text ? (
                ""
              ) : (
                <Marquee
                  speed={setting.speed}
                  style={{
                    fontSize: "35px",
                    position: setting.transparent ? "absolute" : "inherit",
                    bottom: 4,
                    left: 0,
                    width: "100%",
                    backgroundColor: !setting.transparent
                      ? setting.back_colour
                      : "transparent",
                    color: setting.text_colour,
                    zIndex: 1, // Ensure that Marquee appears above Carousel
                  }}
                >
                  {setting.description}
                </Marquee>
              )}
            </Box>
          </div>
          {isMobile ? (
            <div
              style={{
                display: "flex",
                alignItems: "baseline",
                flexDirection: "column",
                width: "350px",
              }}
            >
              <h2 style={{margin: "5px"}}>Settings</h2>
              <div className="input">
                <Select
                  required
                  placeholder="Category"
                  variant="filled"
                  sx={{ width: 300, height: 45 }}
                  options={category}
                  onChange={handleInputChange}
                  value={selectedCategoryName || ""}
                  name="category"
                >
                  {category?.map((option) => {
                    return (
                      <MenuItem
                        key={option.category_id}
                        value={option.display_category}
                      >
                        {option.display_category}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
              <div className="input">
                {/* <label className="label">Text</label> */}
                <TextField
                  required
                  multiline
                  placeholder="Marquee Text Here"
                  id="description"
                  variant="filled"
                  size="small"
                  sx={{ width: 220 }}
                  onChange={handleInputChange}
                  name="description"
                  value={setting.description}
                />
                <div className="input" style={{ marginLeft: "10px" }}>
                  <label>Show</label>
                  <Checkbox
                    name="show_text"
                    onChange={handleInputChange}
                    checked={setting.show_text || false}
                  />
                </div>
              </div>

              <div className="input">
                <label>Background color</label>
                <button
                  style={{
                    backgroundColor: setting.back_colour,
                    width: "40px",
                    height: "20px",
                    marginLeft: "10px",
                  }}
                  onClick={handleBackColorClick}
                />
                {displayColorPicker.backColor && (
                  <div>
                    <div
                      style={{
                        position: "absolute",
                        zIndex: "2",
                      }}
                    >
                      <div
                        style={{
                          position: "fixed",
                          top: "0px",
                          right: "0px",
                          bottom: "0px",
                          left: "0px",
                        }}
                        onClick={handleBackColorClose}
                      />
                      <SketchPicker
                        color={setting.back_colour}
                        onChange={handleBackColorChange}
                      />
                    </div>
                  </div>
                )}

                <label style={{ paddingLeft: "10px" }}>Transparent</label>
                <Checkbox
                  name="transparent"
                  onChange={handleInputChange}
                  checked={setting.transparent || false}
                />
                {/* <label className="label">Transparent</label> */}
              </div>

              <div className="input">
                <label style={{ width: "80px" }}>Text Color</label>
                <button
                  style={{
                    backgroundColor: setting.text_colour,
                    width: "40px",
                    height: "20px",
                    margin: "10px",
                  }}
                  onClick={handleTextColorClick}
                />

                {displayColorPicker.textColor && (
                  <div>
                    <div
                      style={{
                        position: "absolute",
                        zIndex: "2",
                      }}
                    >
                      <div
                        style={{
                          position: "fixed",
                          top: "0px",
                          right: "0px",
                          bottom: "0px",
                          left: "0px",
                        }}
                        onClick={handleTextColorClose}
                      />
                      <SketchPicker
                        color={setting.text_colour}
                        onChange={handleTextColorChange}
                      />
                    </div>
                  </div>
                )}
                <label style={{ margin: "0px 20px 0px 8px" }}>Speed</label>
                <Slider
                  style={{ width: "80px", paddingRight: "10px" }}
                  value={setting.speed || 0}
                  onChange={handleSpeedChange}
                  aria-labelledby="discrete-slider"
                  valueLabelDisplay="auto"
                  step={1}
                  min={0}
                  max={200}
                />
              </div>

              <div className="input">
                <label style={{ paddingRight: "39px" }}>Animation</label>
                <FormControl component="fieldset">
                  <RadioGroup
                    style={{ display: "block" }}
                    aria-label="Animation"
                    name="Animation"
                    value={setting.animation || ""}
                    onChange={handleAnimationChange}
                  >
                    <FormControlLabel
                      style={{ paddingRight: "30px" }}
                      value="slide"
                      control={<Radio />}
                      label="Slide"
                    />
                    <FormControlLabel
                      value="fade"
                      control={<Radio />}
                      label="Fade"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  paddingBottom: "10px",
                  // width: "500px",
                }}
              >
                <Button
                  className="login_button button-primary"
                  sx={{
                    color: "white",
                    width: 300,
                    alignContent: "center",
                  }}
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Submit
                </Button>
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "baseline",
                flexDirection: "column",
              }}
            >
              <div>
                <h2 className="home" style={{ marginLeft: "200px" }}>
                  Settings
                </h2>
              </div>
              <div className="input">
                <label className="label">Category</label>
                <Select
                  required
                  placeholder="Category"
                  variant="filled"
                  sx={{ width: 220, height: 45 }}
                  options={category}
                  onChange={handleInputChange}
                  value={selectedCategoryName || ""}
                  name="category"
                >
                  {category?.map((option) => {
                    return (
                      <MenuItem
                        key={option.category_id}
                        value={option.display_category}
                      >
                        {option.display_category}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
              <div className="input">
                <label className="label">Text</label>
                <TextField
                  required
                  multiline
                  id="description"
                  variant="filled"
                  size="small"
                  sx={{ width: 220, height: 45 }}
                  onChange={handleInputChange}
                  name="description"
                  value={setting.description}
                />
                <label>Show</label>
                <Checkbox
                  name="show_text"
                  onChange={handleInputChange}
                  checked={setting.show_text || false}
                />
              </div>
              <div className="input">
                <label className="label">Background color</label>
                <button
                  style={{
                    backgroundColor: setting.back_colour,
                    width: "40px",
                    height: "20px",
                  }}
                  onClick={handleBackColorClick}
                />
                {displayColorPicker.backColor && (
                  <div>
                    <div
                      style={{
                        position: "absolute",
                        zIndex: "2",
                      }}
                    >
                      <div
                        style={{
                          position: "fixed",
                          top: "0px",
                          right: "0px",
                          bottom: "0px",
                          left: "0px",
                        }}
                        onClick={handleBackColorClose}
                      />
                      <SketchPicker
                        color={setting.back_colour}
                        onChange={handleBackColorChange}
                      />
                    </div>
                  </div>
                )}
                <Checkbox
                  name="transparent"
                  onChange={handleInputChange}
                  checked={setting.transparent || false}
                />
                <label className="label">Transparent</label>
              </div>
              <div className="input">
                <label className="label">Text Color</label>
                <button
                  style={{
                    backgroundColor: setting.text_colour,
                    width: "40px",
                    height: "20px",
                  }}
                  onClick={handleTextColorClick}
                />
                {displayColorPicker.textColor && (
                  <div>
                    <div
                      style={{
                        position: "absolute",
                        zIndex: "2",
                      }}
                    >
                      <div
                        style={{
                          position: "fixed",
                          top: "0px",
                          right: "0px",
                          bottom: "0px",
                          left: "0px",
                        }}
                        onClick={handleTextColorClose}
                      />
                      <SketchPicker
                        color={setting.text_colour}
                        onChange={handleTextColorChange}
                      />
                    </div>
                  </div>
                )}
                <div style={{ display: "flex", marginLeft: "15px" }}>
                  <label className="label">Speed</label>
                  <Slider
                    value={setting.speed || 0}
                    onChange={handleSpeedChange}
                    aria-labelledby="discrete-slider"
                    valueLabelDisplay="auto"
                    step={1}
                    min={0}
                    max={200}
                  />
                </div>
              </div>

              <div className="input">
                <label className="label">Animation</label>
                <FormControl component="fieldset">
                  <RadioGroup
                    style={{ display: "block" }}
                    aria-label="Animation"
                    name="Animation"
                    value={setting.animation || ""}
                    onChange={handleAnimationChange}
                  >
                    <FormControlLabel
                      value="slide"
                      control={<Radio />}
                      label="Slide"
                    />
                    <FormControlLabel
                      value="fade"
                      control={<Radio />}
                      label="Fade"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "500px",
                }}
              >
                <Button
                  className="login_button button-primary"
                  sx={{
                    color: "white",
                    width: 200,
                    alignContent: "center",
                  }}
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Submit
                </Button>
              </div>
            </div>
          )}
        </Paper>
      </div>
    </div>
  );
}
export default Home;
