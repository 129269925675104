import { useState, useEffect, useContext } from "react";

import { Authentication } from "../utilities/Authentication";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormHelperText from "@mui/material/FormHelperText";
import { userDetails } from "../utilities/RequireAuthentication";
import { envDev } from "../App";

function AddImage(props) {
  // const userDetails =  userDetails;
  // const { userDetails, setUserDetails } = useContext(Authentication);
  const [edit, setEdit] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);
  const [menuType,setMenuType]=useState([]);
  // const [isTabview, setTabView] = useState(window.innerWidth <= 1024);
  const [validation, setValidation] = useState({
    name: true,
    menuType: true,
    price: true,
    discountPrice: true,
    selectedCategories: true,
    imageFile: true,
  });

  const initialValues = {
    selectedCategoryIds: [],
    selectedCategories: [],
    imageFile: "",
    name: "Name",
    menuType: "Menu Type",
    productDescription: "",
    price: 0,
    discountPrice: 0,
    categories: [],
  };

  const [imageName, setImageName] = useState("");
  const [imageUpload, setImageUpload] = useState(initialValues);
  const [selectedFileName, setSelectedFileName] = useState("No file chosen");

  const handleCategoryChange = (event) => {
    const { value } = event.target;
    setImageUpload((prev) => {
      const data = { ...prev, selectedCategories: value };
      // setValidation((prevValidation) => ({
      //   ...prevValidation,
      //   selectedCategories: value.length > 0,
      // }));
      return data;
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setImageUpload((prev) => {
      const data = { ...prev, [name]: value };
      if (name !== "productDescription") {
        setValidation((prevValidation) => ({
          ...prevValidation,
          [name]: !!value,
        }));
      }
      return data;
    });
  };

  const getMenuType = async () => {
    const id = userDetails.id;
    try {
      const response = await fetch(
        `${envDev}/getmenutype/${id}`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });

      const data = await response.json();
      setMenuType(data);
    } catch (err) {
      console.log(err.message);
    }
  };

  const uploadImage = async () => {
    imageUpload.selectedCategoryIds = props.categories
      .filter((x) =>
        imageUpload.selectedCategories.includes(x.display_category)
      )
      .map((y) => y.category_id);
    const formData = new FormData();
    formData.append("imageFile", imageUpload.imageFile);
    formData.append("selectedCategories", imageUpload.selectedCategoryIds);
    formData.append("name", imageUpload.name);
    formData.append("menuType", imageUpload.menuType);
    formData.append("productDescription", imageUpload.productDescription);
    formData.append("price", imageUpload.price);
    formData.append("discountPrice", imageUpload.discountPrice);
    formData.append("categoryId", imageUpload.category_id);

    try {
      const response = await fetch(
        `${envDev}/uploadImage/${userDetails.id}-${userDetails.company}`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.status === 200) {
        alert("uploaded Sucessfully");
        props.loading(true);
      } 
    } catch (err) {
      console.error(err.message);
    }
  };

  const updateData = async () => {
    imageUpload.selectedCategoryIds = props.categories
      .filter((x) =>
        imageUpload.selectedCategories.includes(x.display_category)
      )
      .map((y) => y.category_id);

    try {
      const response = await fetch(
        `${envDev}/editimage/${imageUpload.imageFile}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ ...imageUpload, userId: userDetails.id }),
        }
      );

      if (response.status === 200) {
        alert("updated Sucessfully");
        props.loading(true);
      } 
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    // Handle focus or other actions when `selectedRow` changes.
    if (props.selectedRow) {
      const selectedCategoriesArray = props.selectedRow.categories
        // .split(",")
        .map((category) => category.display_category);
      setImageUpload({
        selectedCategories: selectedCategoriesArray,
        imageFile: props.selectedRow.image_id,
        name: props.selectedRow.name,
        menuType: props.selectedRow.menu_type,
        productDescription: props.selectedRow.product_description,
        price: props.selectedRow.price,
        discountPrice: props.selectedRow.discount_price,
        categories: props.selectedRow.categories,
      });
      setImageName(props.selectedRow.image_name);
      setEdit(true);
    }
  }, [props.selectedRow]);

  const reset = () => {
    setImageUpload({
      ...initialValues,
      imageFile: null, // Reset imageFile to null
    });
    setImageName("");
    setSelectedFileName("No File Choosen");
  };

  const cancel = () => {
    setEdit(false);
    setImageUpload({
      ...initialValues,
      imageFile: null, // Reset imageFile to null
    });
    setImageName("");
    setSelectedFileName("No File Choosen");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Check if selectedCategories is empty
    if (!imageUpload.imageFile) {
      alert("Please upload an image.");
      return; // Prevent submission
    } else if (
      isNaN(imageUpload.price) ||
      imageUpload.price === 0 ||
      isNaN(imageUpload.discountPrice)
    ) {
      alert("Please enter valid Price or discount price");
      return; // Prevent submission
    } else if (imageUpload.selectedCategories.length === 0) {
      alert("Please select at least one category.");
      return; // Prevent submission
    }

    const shouldUpload = window.confirm("Are you sure you want to upload?");

    if (shouldUpload) {
      uploadImage();
    } else {
      alert("Uploading canceled.");
    }
    reset();
  };

  const handleEdit = (e) => {
    e.preventDefault();
    const shouldUpload = window.confirm("Are you sure you want to update?");

    if (shouldUpload) {
      updateData();
    } else {
      alert("Update canceled.");
    }
    cancel();
  };

  useEffect(() => {
    getMenuType();
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
      // setTabView(window.innerWidth <= 1024);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array means this effect runs once on mount

  return (
    <div>
      {isMobile ? (
        <div>
          <span className="heading">
            <strong>{edit ? "Edit Product Details" : "Add Image"}</strong>
          </span>
          <div
            style={{
              border: "1.5px solid #e0e0e0",
              width: "95vw",
              // overflow:"scroll",
              // margin: "auto 10px",
            }}
          >
            {edit ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "20px",
                }}
              >
                <img
                  src={`${envDev}/images/${userDetails.company}/${imageName}`}
                  style={{ width: "50vw" }}
                />
                {/* <label className="label">
                  {edit ? "Uploaded Image" : "Image Upload"}
                </label>
                <label style={{ margin: "20px" }}>{imageName}</label> */}
              </div>
            ) : (
              <div className="input">
                <label className="label">Image Upload</label>
                <input
                  type="file"
                  id="fileInput" // Give the input element an ID
                  style={{ display: "none" }} // Hide the input element
                  onChange={(event) => {
                    const { name, type, files } = event.target;

                    // Handle file input separately
                    setImageUpload({
                      ...imageUpload,
                      [name]: files[0], // Use files[0] to get the uploaded file
                    });

                    // Update the selectedFileName state
                    const newSelectedFileName = files[0]
                      ? files[0].name
                      : "No file chosen";
                    setSelectedFileName(newSelectedFileName);
                  }}
                  name="imageFile"
                />
                <label
                  htmlFor="fileInput"
                  style={{
                    cursor: "pointer",
                    padding: "10px",
                    border: "1px solid #ccc",
                    backgroundColor: "#f0f0f0",
                  }}
                >
                  Choose File
                </label>
                <p>{selectedFileName}</p>
              </div>
            )}

            <div className="input">
              <label className="label">Name</label>
              <TextField
                required
                id="Name"
                variant="filled"
                size="small"
                sx={{ padding: "0px 10px 10px 0px", width: "52vw" }}
                // style={{size: isTabview
                //   ? "100vw"
                //   : "",}}
                onChange={handleChange}
                name="name"
                value={imageUpload.name}
                error={!validation.name}
                helperText={!validation.name && "Name is required"}
              />
            </div>
            <div className="input">
              <label className="label">Menu Type</label>
              <Select
                required
                placeholder="MenuType"
                variant="filled"
                sx={{ width: 250 }}
                options={menuType}
                onChange={handleChange}
                value={imageUpload.menuType}
                name="menuType"
              >
                {menuType?.map((option) => {
                  return (
                    <MenuItem
                      key={option.id}
                      value={option.menu_type}
                    >
                      {option.menu_type}
                    </MenuItem>
                  );
                })}
              </Select>
              {/* <TextField
                required
                id="MenuType"
                variant="filled"
                size="small"
                sx={{ padding: "0px 10px 10px 0px", width: "52vw" }}
                onChange={handleChange}
                name="menuType"
                value={imageUpload.menuType}
                error={!validation.menuType}
                helperText={!validation.menuType && "Menu Type is required"}
              /> */}
            </div>
            <div className="input">
              <label className="label">Description</label>
              <TextField
                required
                multiline
                id="description"
                variant="filled"
                size="small"
                sx={{ padding: "0px 10px 10px 0px", width: "52vw" }}
                onChange={handleChange}
                name="productDescription"
                value={imageUpload.productDescription}
              />
            </div>
            <div className="input">
              <label className="label">Price</label>
              <TextField
                required
                id="Price"
                variant="filled"
                size="small"
                type="number"
                sx={{ padding: "0px 10px 10px 0px", width: "52vw" }}
                onChange={handleChange}
                name="price"
                value={imageUpload.price}
              />
            </div>
            <div className="input">
              <label className="label">Discount Price</label>
              <TextField
                required
                id="DiscountPrice"
                placeholder="Discount Price"
                variant="filled"
                size="small"
                type="number"
                sx={{ padding: "0px 10px 10px 0px", width: "52vw" }}
                onChange={handleChange}
                name="discountPrice"
                value={imageUpload.discountPrice}
                error={!validation.discountPrice}
                helperText={
                  !validation.discountPrice && "Enter 0 for no discount."
                }
              />
            </div>
            <div className="input">
              <label className="label">Category</label>
              <FormControl variant="filled" sx={{ width: "53vw" }}>
                <Select
                  labelId="category-label"
                  id="category-select"
                  multiple
                  value={imageUpload.selectedCategories}
                  onChange={handleCategoryChange}
                  renderValue={(selected) => selected.join(", ")}
                  error={!validation.selectedCategories}
                  displayEmpty
                >
                  {props.categories.map((option) => (
                    <MenuItem
                      key={option.category_id}
                      value={option.display_category}
                    >
                      <Checkbox
                        checked={
                          imageUpload.selectedCategories.indexOf(
                            option.display_category
                          ) > -1
                        }
                      />
                      {option.display_category}
                    </MenuItem>
                  ))}
                </Select>
                {!validation.selectedCategories && (
                  <FormHelperText error>
                    Select at least one category
                  </FormHelperText>
                )}
              </FormControl>
            </div>

            {edit ? (
              <div className="submit-btns">
                <Button
                  className="add-prod-btn button-primary"
                  variant="contained"
                  type="button"
                  onClick={handleEdit}
                  disabled={!Object.values(validation).every(Boolean)}
                >
                  Save
                </Button>
                <Button
                  className="add-prod-btn button-primary"
                  variant="contained"
                  type="button"
                  onClick={cancel}
                >
                  Cancel
                </Button>
              </div>
            ) : (
              <div className="submit-btns">
                <Button
                  className="add-prod-btn button-primary"
                  variant="contained"
                  type="button"
                  onClick={handleSubmit}
                  disabled={!Object.values(validation).every(Boolean)}
                >
                  Submit
                </Button>
                <Button
                  className="add-prod-btn button-primary"
                  variant="contained"
                  type="button"
                  onClick={reset}
                >
                  Reset
                </Button>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div>
          <span className="heading">
            <strong>{edit ? "Edit Product Details" : "Add Image"}</strong>
          </span>
          <div
            className="outer-box"
            style={{ width: "450px", height: "73vh", overflowY: "auto" }}
          >
            {edit ? (
              <div className="input">
                <label className="label">
                  {edit ? "Uploaded Image" : "Image Upload"}
                </label>
                <label style={{ margin: "20px" }}>{imageName}</label>
              </div>
            ) : (
              <div className="input">
                <label className="label">Image Upload</label>
                <input
                  type="file"
                  id="fileInput" // Give the input element an ID
                  style={{ display: "none" }} // Hide the input element
                  onChange={(event) => {
                    const { name, type, files } = event.target;

                    // Handle file input separately
                    setImageUpload({
                      ...imageUpload,
                      [name]: files[0], // Use files[0] to get the uploaded file
                    });

                    // Update the selectedFileName state
                    const newSelectedFileName = files[0]
                      ? files[0].name
                      : "No file chosen";
                    setSelectedFileName(newSelectedFileName);
                  }}
                  name="imageFile"
                />
                <label
                  htmlFor="fileInput"
                  style={{
                    cursor: "pointer",
                    padding: "10px",
                    border: "1px solid #ccc",
                    backgroundColor: "#f0f0f0",
                  }}
                >
                  Choose File
                </label>
                <p>{selectedFileName}</p>
              </div>
            )}

            <div className="input">
              <label className="label">Name</label>
              <TextField
                required
                id="Name"
                variant="filled"
                size="small"
                sx={{ padding: "0px 10px 10px 0px" }}
                onChange={handleChange}
                name="name"
                value={imageUpload.name}
                error={!validation.name}
                helperText={!validation.name && "Name is required"}
              />
            </div>
            <div className="input">
              <label className="label">Menu Type</label>
              <Select
                required
                placeholder="MenuType"
                variant="filled"
                sx={{ width: 230, height: "50px" }}
                options={menuType}
                onChange={handleChange}
                value={imageUpload.menuType}
                name="menuType"
              >
                {menuType?.map((option) => {
                  return (
                    <MenuItem
                      key={option.id}
                      value={option.menu_type}
                    >
                      {option.menu_type}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
            <div className="input">
              <label className="label">Description</label>
              <TextField
                required
                multiline
                id="description"
                variant="filled"
                size="small"
                sx={{ padding: "0px 10px 10px 0px", width: "220px" }}
                onChange={handleChange}
                name="productDescription"
                value={imageUpload.productDescription}
              />
            </div>
            <div className="input">
              <label className="label">Price</label>
              <TextField
                required
                id="Price"
                variant="filled"
                size="small"
                sx={{ padding: "0px 10px 10px 0px" }}
                onChange={handleChange}
                name="price"
                value={imageUpload.price}
                error={!validation.price}
                helperText={!validation.price && "Price is required"}
              />
            </div>
            <div className="input">
              <label className="label">Discount Price</label>
              <TextField
                required
                id="DiscountPrice"
                variant="filled"
                size="small"
                sx={{ padding: "0px 10px 10px 0px" }}
                onChange={handleChange}
                name="discountPrice"
                value={imageUpload.discountPrice}
                error={!validation.discountPrice}
                helperText={
                  !validation.discountPrice && "Enter 0 for no discount"
                }
              />
            </div>
            <div className="input">
              <label className="label">Category</label>
              <FormControl variant="filled">
                <Select
                  labelId="category-label"
                  id="category-select"
                  multiple
                  sx={{ width: 230, height: "50px" }}
                  value={imageUpload.selectedCategories}
                  onChange={handleCategoryChange}
                  renderValue={(selected) => selected.join(", ")}
                  error={!validation.selectedCategories}
                  displayEmpty
                >
                  {props.categories.map((option) => (
                    <MenuItem
                      key={option.category_id}
                      value={option.display_category}
                    >
                      <Checkbox
                        checked={
                          imageUpload.selectedCategories.indexOf(
                            option.display_category
                          ) > -1
                        }
                      />
                      {option.display_category}
                    </MenuItem>
                  ))}
                </Select>
                {!validation.selectedCategories && (
                  <FormHelperText error>
                    Select at least one category
                  </FormHelperText>
                )}
              </FormControl>
            </div>

            {edit ? (
              <div className="submit-btns">
                <Button
                  className="add-prod-btn button-primary"
                  variant="contained"
                  type="button"
                  onClick={handleEdit}
                  disabled={!Object.values(validation).every(Boolean)}
                >
                  Save
                </Button>
                <Button
                  className="add-prod-btn button-primary"
                  variant="contained"
                  type="button"
                  onClick={cancel}
                >
                  Cancel
                </Button>
              </div>
            ) : (
              <div className="submit-btns">
                <Button
                  className="add-prod-btn button-primary"
                  variant="contained"
                  type="button"
                  onClick={handleSubmit}
                  disabled={!Object.values(validation).every(Boolean)}
                >
                  Submit
                </Button>
                <Button
                  className="add-prod-btn button-primary"
                  variant="contained"
                  type="button"
                  onClick={reset}
                >
                  Reset
                </Button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
export default AddImage;
