import React from "react";
import { useParams } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import frontpage from "../assets/0SM.jpg";

import { Authentication } from "../utilities/Authentication";

import Typography from "@mui/material/Typography";
import Marquee from "react-fast-marquee";
import Carousel from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { userDetails } from "../utilities/RequireAuthentication";
import { envDev } from "../App";

const Preview = () => {
  // const { userDetails, setUserDetails } = useContext(Authentication);
  const [setting, setSetting] = useState({});
  const { users_id } = useParams(); // Use the useParams hook to get the value of users_id
  const [userDetailsFromId, setUserDetailsFromId] = useState([]);
  const [categoryImages, setcategoryImages] = useState([]);

  const getUser = async () => {
    try {
      const response = await fetch(
        `${envDev}/getuser/${users_id}`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      );

      const data = await response.json();
      // console.log(data.rows[0]);
      setUserDetailsFromId(data[0]);
    } catch (err) {
      console.log(err.message);
    }
  };

  const getSetting = async () => {
    const id = userDetails.id;
    try {
      const response = await fetch(`${envDev}/getsetting/${id}`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });

      const data = await response.json();

      const d = data[0];
      const transformedData = {
        description: d.description,
        show_text: d.show_text,
        back_colour: d.back_colour,
        text_colour: d.text_colour,
        animation: d.animation,
        transparent: d.transparent,
        speed: d.speed,
        show_image: d.show_image,
        selected_category: d.selected_category,
      };
      data.forEach((element) => {
        if (!transformedData[element.category_id]) {
          transformedData[element.category_id] = [];
        }
        if (element.show_image) {
          transformedData[element.category_id].push(element.image_name);
        }
        // image_names: d.show_image ? [curr.image_name] : [],
      });

      setSetting(transformedData);
      if (transformedData[d.selected_category]) {
        setcategoryImages(transformedData[d.selected_category]);
      }
    } catch (err) {
      console.log(err.message);
    }
  };
  useEffect(() => {
    if (users_id) {
      getUser();
    }
    getSetting();
  }, []);

  return (
    <div style={{
      position: "relative",
      backgroundColor: !setting.transparent
              ? setting.back_colour
              : "transparent",
            color: setting.text_colour,
    }}>
      {categoryImages?.length ? (
        <Carousel
          autoPlay
          interval={500} // Adjust the interval as needed (in milliseconds)
          infiniteLoop
          arrows={false}
          autoplay={true}
          fade={setting.animation === "fade"}
          animation="slide"
        >
          {categoryImages?.length > 0 ? (
            categoryImages.map((data) => (
              <div key={data}>
                <img
                  src={`${envDev}/images/${
                    userDetails.company
                      ? userDetails.company
                      : userDetailsFromId.company
                  }/${data}`}
                  alt={`Image ${data}`}
                  style={{
                    width: "100vw",
                    height: setting.transparent ? "99.4vh" : "86vh",
                  }}
                />
              </div>
            ))
          ) : (
            <Typography
              component="div"
              sx={{ position: "absolute", top: "50%", left: "45%" }}
            >
              -- No Products Found --
            </Typography>
          )}
        </Carousel>
      ) : (
        <img className="image-size" alt="loading" src={frontpage}></img>
      )}

      {!setting.show_text ? (
        ""
      ) : (
        <Marquee
          speed={setting.speed}
          style={{
            fontSize: "70px",
            position: setting.transparent ? "absolute" : "inherit",
            bottom: 0,
            left: 0,
            width: "100%",
            backgroundColor: !setting.transparent
              ? setting.back_colour
              : "transparent",
            color: setting.text_colour,
            zIndex: 1, // Ensure that Marquee appears above Carousel
          }}
        >
          {setting.description}
        </Marquee>
      )}
    </div>
  );
};

export default Preview;
