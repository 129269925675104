import { useState, useEffect, useContext } from "react";

import { Authentication } from "../utilities/Authentication";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import {
  setUserDetails,
  userDetails,
} from "../utilities/RequireAuthentication";
import { envDev } from "../App";

function Category() {
  // const { userDetails, setUserDetails } = useContext(Authentication);
  const [category, setCategory] = useState([]);
  const [menuType, setMenuType] = useState([]);

  const initialValues = {
    changedCategory: "",
    newCategory: "",
  };
  const [categoryChange, setCategoryChange] = useState(initialValues);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 865);
  const [currency, setCurrency] = useState(userDetails.currency);
  const [deleteMenuType, setDeleteMenuType] = useState("");
  const [shippingCharges, setShippingCharges] = useState(
    userDetails.shipping_charges
  );
  // const [isFormValid, setIsFormValid] = useState(false); // New state variable

  const initialData = {
    imageFile: "",
    menuType: "",
  };

  const [imageUpload, setImageUpload] = useState(initialData);
  const [selectedFileName, setSelectedFileName] = useState("No file chosen");

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === "currency") {
      setCurrency(value);
    } else if (name === "shippingCharges") {
      setShippingCharges(value);
    } else if (name === "menuType") {
      setDeleteMenuType(value);
    } else {
      setCategoryChange({
        ...categoryChange,
        [name]: value,
      });
    }
  };

  const getCategory = async () => {
    const id = userDetails.id;
    try {
      const response = await fetch(`${envDev}/categories/${id}`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });

      const data = await response.json();
      setCategory(data);
    } catch (err) {
      console.log(err.message);
    }
  };

  const getMenuType = async () => {
    const id = userDetails.id;
    try {
      const response = await fetch(`${envDev}/getmenutype/${id}`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });

      const data = await response.json();
      setMenuType(data);
    } catch (err) {
      console.log(err.message);
    }
  };

  const deleteMenu = async (id) => {
    try {
      const response = await fetch(
        `${envDev}/deletemenutype/${id}-${userDetails.id}-${deleteMenuType}`,
        {
          method: "DELETE",
          headers: { "Content-Type": "application/json" },
        }
      );

      const data = await response.json();
      // Check if the response contains a message property
      if (data.message) {
        // Display the message in the UI
        alert(data.message);
        getMenuType();
        setDeleteMenuType('')
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    getCategory();
  }, []);

  // useEffect(() => {
  //   // Update form validity whenever categoryChange changes
  //   const isValid = categoryChange.changedCategory !== "" && categoryChange.newCategory !== "";
  //   setIsFormValid(isValid);
  // }, [categoryChange]);

  const updateData = async () => {
    try {
      const response = await fetch(
        `${envDev}/updatecategory/${userDetails.id}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(categoryChange),
        }
      );
      const data = await response.json();
      setCategory(data);
    } catch (err) {
      console.error(err.message);
    }
  };

  const updateCurrency = async () => {
    try {
      const details = { currency, shippingCharges };
      const response = await fetch(
        `${envDev}/updatecurrency/${userDetails.id}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(details),
        }
      );
      const data = await response.json();
      localStorage.setItem("userDetails", JSON.stringify(data[0]));
      setUserDetails(data[0]);
    } catch (err) {
      console.error(err.message);
    }
  };

  const addMenuType = async () => {
    const formData = new FormData();
    formData.append("imageFile", imageUpload.imageFile);
    formData.append("menuType", imageUpload.menuType);

    try {
      const response = await fetch(
        `${envDev}/addmenutype/${userDetails.id}-${
          userDetails.company
        }-${"menuicon"}`,
        {
          method: "POST",
          body: formData,
        }
      );

      const data = await response.json();

      if (response.status === 200) {
        alert("uploaded Sucessfully");
        getMenuType();
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      categoryChange.changedCategory === "" ||
      categoryChange.newCategory === ""
    ) {
      alert("Please Enter Valid Details.");
      return; // Prevent submission
    }

    const shouldUpload = window.confirm(
      "Are you sure you want to change category?"
    );

    if (shouldUpload) {
      // alert("Uploading...");
      updateData();
    } else {
      alert("Canceled changing category.");
    }
    setCategoryChange(initialValues);
  };

  const handleDeleteMenuType = (e) => {
    const data = menuType.find((x) => x.menu_type === deleteMenuType);
    deleteMenu(data.id);
  };

  const handleCurrencySubmit = (e) => {
    e.preventDefault();
    const shouldUpload = window.confirm(
      "Are you sure you want to change currency and shipping charges?"
    );

    if (shouldUpload) {
      // alert("Uploading...");
      updateCurrency();
    } else {
      alert("Canceled changing currency and shipping charges.");
    }
    setShippingCharges(userDetails.shipping_charges);
  };

  const handleMenuType = (e) => {
    e.preventDefault();
    const shouldUpload = window.confirm(
      "Are you sure you want to Add Menu Type ?"
    );

    if (shouldUpload) {
      // alert("Uploading...");
      addMenuType();
    } else {
      alert("Canceled adding Menu type.");
    }
    setImageUpload(initialData);
  };

  useEffect(() => {
    const data = category.find(
      (x) => x.display_category === categoryChange.changedCategory
    );
    if (data) {
      setCategoryChange((prevState) => ({
        ...prevState,
        changedCategory_id: data.category_id,
      }));
    }
  }, [categoryChange.changedCategory]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 865);
    };
    getMenuType();

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array means this effect runs once on mount

  return (
    <div>
      <AppBar position="static" className="app-bar">
        <form>
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              sx={{
                display: "flex",
                fontWeight: 600,
                fontSize: "20px",
                textDecoration: "none",
                marginBottom: "10px",
                marginLeft: "15px",
              }}
            >
              Settings
            </Typography>
          </Toolbar>
        </form>
      </AppBar>
      {isMobile ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Paper
            style={{
              margin: "50px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              alignItems: "center",
              border: "1px solid lightgrey",
            }}
          >
            <Typography
              style={{
                fontWeight: "700",
                fontSize: "20px",
                margin: "10px",
                fontFamily: "sans-serif",
              }}
            >
              Change Category Name
            </Typography>
            <div className="input">
              <Select
                required
                placeholder="Category"
                variant="filled"
                sx={{ width: 250 }}
                options={category}
                onChange={handleChange}
                value={categoryChange.changedCategory}
                name="changedCategory"
              >
                {category?.map((option) => {
                  return (
                    <MenuItem
                      key={option.category_id}
                      value={option.display_category}
                    >
                      {option.display_category}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
            <div className="input">
              {/* <label className="label">Rename</label> */}
              <TextField
                required
                id="newCategory"
                variant="filled"
                placeholder="Rename"
                sx={{ padding: "0px 0px 10px 0px", width: 250 }}
                onChange={handleChange}
                name="newCategory"
                value={categoryChange.newCategory}
              />
            </div>
            <button
              className="login_button button-primary"
              onClick={handleSubmit}
            >
              Submit
            </button>
            <Typography
              style={{
                fontWeight: "700",
                fontSize: "20px",
                margin: "10px",
                fontFamily: "sans-serif",
              }}
            >
              Currency and Shipping Charges
            </Typography>
            <div className="input">
              <Select
                required
                variant="filled"
                sx={{ width: 250 }}
                value={currency} // Change this line
                name="currency"
                onChange={handleChange}
              >
                <MenuItem value="AED">AED</MenuItem>
                <MenuItem value="INR">INR</MenuItem>
              </Select>
            </div>
            <div className="input">
              {/* <label className="label">Rename</label> */}
              <TextField
                required
                type="number"
                variant="filled"
                placeholder="Shipping Charges"
                sx={{ padding: "0px 0px 10px 0px", width: 250 }}
                onChange={handleChange}
                name="shippingCharges"
                value={shippingCharges}
              />
            </div>
            <button
              className="login_button button-primary"
              onClick={handleCurrencySubmit}
            >
              Submit
            </button>
            <Typography
              style={{
                fontWeight: "700",
                fontSize: "20px",
                fontFamily: "sans-serif",
              }}
            >
              Add Menu Type
            </Typography>
            <div className="input">
              <input
                type="file"
                id="fileInput" // Give the input element an ID
                style={{ display: "none" }} // Hide the input element
                onChange={(event) => {
                  const { name, type, files } = event.target;

                  // Handle file input separately
                  setImageUpload({
                    ...imageUpload,
                    [name]: files[0], // Use files[0] to get the uploaded file
                  });

                  // Update the selectedFileName state
                  const newSelectedFileName = files[0]
                    ? files[0].name
                    : "No file chosen";
                  setSelectedFileName(newSelectedFileName);
                }}
                name="imageFile"
              />
              <label
                htmlFor="fileInput"
                style={{
                  cursor: "pointer",
                  padding: "10px",
                  border: "1px solid #ccc",
                  backgroundColor: "#f0f0f0",
                }}
              >
                Choose File
              </label>
              <p>{selectedFileName}</p>
            </div>
            <TextField
              required
              placeholder="Menu Type"
              variant="filled"
              sx={{ padding: "0px 0px 10px 0px", width: 250 }}
              onChange={(event) => {
                const { name, value } = event.target;

                // Handle file input separately
                setImageUpload({
                  ...imageUpload,
                  [name]: value, // Use files[0] to get the uploaded file
                });
              }}
              name="menuType"
              value={imageUpload.menuType}
            />
            <button
              className="login_button button-primary"
              onClick={handleMenuType}
              // disabled={!isFormValid} // Disable the button if the form is not valid
            >
              Submit
            </button>
            <Typography
              style={{
                fontWeight: "700",
                fontSize: "20px",
                fontFamily: "sans-serif",
              }}
            >
              Delete Menu Type
            </Typography>{" "}
            <Select
              required
              placeholder="MenuType"
              variant="filled"
              sx={{ width: 250, height: "50px", marginBottom: "10px" }}
              options={menuType}
              onChange={handleChange}
              value={deleteMenuType}
              name="menuType"
            >
              {menuType?.map((option) => {
                return (
                  <MenuItem key={option.id} value={option.menu_type}>
                    {option.menu_type}
                  </MenuItem>
                );
              })}
            </Select>
            <button
              className="login_button button-primary"
              onClick={handleDeleteMenuType}
              // disabled={!isFormValid} // Disable the button if the form is not valid
            >
              Submit
            </button>
          </Paper>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            height: "77.8vh",
          }}
        >
          <Paper
            style={{
              width: "1000px",
              height: "100vh",
              margin: "50px 70px",
              padding: "30px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              border: "1px solid lightgrey",
            }}
          >
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography
                  style={{
                    fontWeight: "700",
                    fontSize: "20px",
                    fontFamily: "sans-serif",
                  }}
                >
                  Change Category Name
                </Typography>
                <div className="input">
                  <label className="label">Category</label>
                  <Select
                    required
                    placeholder="Category"
                    variant="filled"
                    sx={{ width: 250 }}
                    options={category}
                    onChange={handleChange}
                    value={categoryChange.changedCategory}
                    name="changedCategory"
                  >
                    {category?.map((option) => {
                      return (
                        <MenuItem
                          key={option.category_id}
                          value={option.display_category}
                        >
                          {option.display_category}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </div>
                <div className="input">
                  <label className="label">Rename</label>
                  <TextField
                    required
                    variant="filled"
                    sx={{ padding: "0px 0px 10px 0px", width: 250 }}
                    onChange={handleChange}
                    name="newCategory"
                    value={categoryChange.newCategory}
                  />
                </div>
                <button
                  className="login_button button-primary"
                  onClick={handleSubmit}
                  // disabled={!isFormValid} // Disable the button if the form is not valid
                >
                  Submit
                </button>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography
                  style={{
                    fontWeight: "700",
                    fontSize: "20px",
                    fontFamily: "sans-serif",
                  }}
                >
                  Currency and Shipping Charges
                </Typography>
                <div className="input">
                  <label className="label">Currency</label>
                  <Select
                    required
                    variant="filled"
                    sx={{ width: 250 }}
                    value={currency} // Change this line
                    name="currency"
                    onChange={handleChange}
                  >
                    <MenuItem value="AED">AED</MenuItem>
                    <MenuItem value="INR">INR</MenuItem>
                  </Select>
                </div>
                <div className="input">
                  <label className="label">Shipping Charges</label>
                  <TextField
                    required
                    type="number"
                    id="newCategory"
                    variant="filled"
                    sx={{ padding: "0px 0px 10px 0px", width: 250 }}
                    onChange={handleChange}
                    name="shippingCharges"
                    value={shippingCharges}
                  />
                </div>
                <button
                  className="login_button button-primary"
                  onClick={handleCurrencySubmit}
                  // disabled={!isFormValid} // Disable the button if the form is not valid
                >
                  Submit
                </button>
              </div>
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography
                  style={{
                    fontWeight: "700",
                    fontSize: "20px",
                    fontFamily: "sans-serif",
                  }}
                >
                  Add Menu Type
                </Typography>

                <div className="input">
                  <label className="label">Image Upload</label>
                  <input
                    type="file"
                    id="fileInput" // Give the input element an ID
                    style={{ display: "none" }} // Hide the input element
                    onChange={(event) => {
                      const { name, type, files } = event.target;

                      // Handle file input separately
                      setImageUpload({
                        ...imageUpload,
                        [name]: files[0], // Use files[0] to get the uploaded file
                      });

                      // Update the selectedFileName state
                      const newSelectedFileName = files[0]
                        ? files[0].name
                        : "No file chosen";
                      setSelectedFileName(newSelectedFileName);
                    }}
                    name="imageFile"
                  />
                  <label
                    htmlFor="fileInput"
                    style={{
                      cursor: "pointer",
                      padding: "10px",
                      border: "1px solid #ccc",
                      backgroundColor: "#f0f0f0",
                    }}
                  >
                    Choose File
                  </label>
                  <p>{selectedFileName}</p>
                </div>
                <div className="input">
                  <label className="label">Menu Type</label>
                  <TextField
                    required
                    variant="filled"
                    sx={{ padding: "0px 0px 10px 0px", width: 250 }}
                    onChange={(event) => {
                      const { name, value } = event.target;

                      // Handle file input separately
                      setImageUpload({
                        ...imageUpload,
                        [name]: value, // Use files[0] to get the uploaded file
                      });
                    }}
                    name="menuType"
                    value={imageUpload.menuType}
                  />
                </div>
                <button
                  className="login_button button-primary"
                  onClick={handleMenuType}
                  // disabled={!isFormValid} // Disable the button if the form is not valid
                >
                  Submit
                </button>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography
                  style={{
                    fontWeight: "700",
                    fontSize: "20px",
                    fontFamily: "sans-serif",
                  }}
                >
                  Delete Menu Type
                </Typography>{" "}
                <div className="input">
                  <label className="label">Menu Type</label>
                  <Select
                    required
                    placeholder="MenuType"
                    variant="filled"
                    sx={{ width: 230, height: "50px" }}
                    options={menuType}
                    onChange={handleChange}
                    value={deleteMenuType}
                    name="menuType"
                  >
                    {menuType?.map((option) => {
                      return (
                        <MenuItem key={option.id} value={option.menu_type}>
                          {option.menu_type}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </div>{" "}
                <button
                  className="login_button button-primary"
                  onClick={handleDeleteMenuType}
                  // disabled={!isFormValid} // Disable the button if the form is not valid
                >
                  Submit
                </button>
              </div>
            </div>
          </Paper>
        </div>
      )}
    </div>
  );
}
export default Category;
