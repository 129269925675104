import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import React, { useContext, useState } from "react";

import logo from "../assets/smartmiddleeast.png";
import "../utilities/style.scss";
import { Authentication } from "../utilities/Authentication";


import TextField from "@mui/material/TextField";
import AccountCircle from "@mui/icons-material/AccountCircle";
import LockIcon from "@mui/icons-material/Lock";
import InputAdornment from "@mui/material/InputAdornment";
import { setUserDetails, userDetails } from "../utilities/RequireAuthentication";
import { envDev } from "../App";

function Login() {
  // const { userDetails, setUserDetails } = useContext(Authentication);

  localStorage.clear();
  
  const initialValues = {
    username: "",
    password: "",
  };

  const [credentials, setCredentials] = useState(initialValues);

  const navigate = useNavigate();
  const location = useLocation();

  const redirectPath = location.state?.path || "/home";

  const handleChange = (event) => {
    const { name, value } = event.target;
    setCredentials((prev) => {
      const cred = { ...prev, [name]: value };
      return cred;
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchData();
  };

  const fetchData = async () => {
    try {
      const response = await fetch(`${envDev}/users/login`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(credentials),
      });
      const data = await response.json();

      if (response.status === 401) {
        alert("Invalid Username or Password");
      } else {
        localStorage.setItem("userDetails", JSON.stringify(data.rows[0]));
        setUserDetails(data.rows[0]);
        navigate(redirectPath, { replace: true });
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  return (
    <div className="login-container login_background height">
      <div className="login_wrapper">
        <div className="text-center">
          <h1>Smart Menu</h1>
          {/* <img
            src={logo}
            alt="logo"
            style={{ marginTop: "20px", maxWidth: "270px", height: "auto" }}
          /> */}
          <form onSubmit={handleSubmit}>
            <div className="login_input">
              <TextField
                required
                id="filled-basic"
                label="Username"
                name="username"
                variant="filled"
                onChange={handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="login_input">
              <TextField
                id="outlined-password-input"
                required
                label="Password"
                type="password"
                name="password"
                variant="filled"
                onChange={handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <button className="login_button button-primary" type="submit">
              Login
            </button>
            {/* <div className="supporter">
              <Link className="login_link" to="/forgotpwd">
                Forgot Password?
              </Link>
            </div> */}
          </form>
        </div>
        <div className="signup-wrapper text-center">
          <Link className="login_link" to="/forgotpwd">
            Forgot Password?
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Login;
