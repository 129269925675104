import * as React from "react";

import { useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";

import "../utilities/style.scss";

import AccountCircle from "@mui/icons-material/AccountCircle";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
// import Tiktok from "@mui/icons-material/Tiktok";
import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { userDetails } from "../utilities/RequireAuthentication";

function DashBoard() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);

  const settings = [
    {
      name: "Profile",
      route: "/profile",
    },

    {
      name: "Logout",
      route: "/login",
    },
  ];

  const pages = [
    {
      name: "Home",
      route: "/home",
      show: true,
    },
    {
      name: "Products",
      route: "/imageentry",
      show: true,
    },
    {
      name: "Category",
      route: "/list",
      show: true,
    },
    {
      name: "Orders",
      route: `/ordersummary`,
      show: true,
    },

    {
      name: "Settings",
      route: "/category",
      show: true,
    },
    {
      name: "Preview",
      route: `/preview/${userDetails.id}`,
      show: true,
    },
  ];
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  // const handleUserMenu = (setting) => {
  //   if (setting.name === "Logout") {
  //     localStorage.clear();
  //   }
  //   const redirectPath = location.state?.path || setting.route;
  //   navigate(redirectPath, { replace: true });
  //   // setAnchorElUser(null);
  // };
  const handleCloseUserMenu = (setting) => {
    setAnchorElUser(null);
  };

  useEffect(() => {
    if (location.pathname === "/") {
      navigate("/home", { replace: true });
    }
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };
    console.log(userDetails)

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array means this effect runs once on mount

  return (
    <div>
      <AppBar position="static">
        {isMobile ? (
          <Toolbar
            style={{
              backgroundColor: "#283223",
              height: "100px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box sx={{ flexGrow: 0 }}>
              <span>Welcome {userDetails.company_alias_name}</span>
              <Tooltip title="Open settings">
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenUserMenu}
                  color="inherit"
                  // style={{ marginRight: "15px" }}
                >
                  <AccountCircle />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem
                    key={setting.name}
                    component={Link}
                    to={setting.route}
                    onClick={handleCloseUserMenu}
                  >
                    <Typography textAlign="center">{setting.name}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <div
              style={{
                display: "flex",
                width: "95vw",
                justifyContent: "space-between",
              }}
            >
              <Typography
                variant="h6"
                noWrap
                component="a"
                // href="/home"
                sx={{
                  mr: 2,
                  display: "flex",
                  fontWeight: 700,
                  letterSpacing: ".3rem",
                  color: "inherit",
                  textDecoration: "none",
                  background:
                    "linear-gradient(45deg, #43ca86 0%,#19d676 50%,#19d676 100%)",
                  borderRadius: "20px",
                  // marginRight: "50px",
                  padding: "5px",
                  marginTop: "5px",
                }}
              >
                Smart Menu
              </Typography>
              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Open settings">
                  <IconButton
                    size="large"
                    aria-label="menu for mobile"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleOpenNavMenu}
                    color="inherit"
                    // style={{ marginLeft: "auto" }}
                  >
                    <MenuIcon />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                >
                  {pages.map((page) => (
                    <MenuItem
                      key={page.name}
                      component={Link}
                      to={page.route}
                      onClick={handleCloseNavMenu}
                      target={page.name === "Preview" ? "_blank" : "_self"} // Use "_blank" for "Preview" and "_self" for others
                    >
                      <Typography textAlign="center">{page.name}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </div>
          </Toolbar>
        ) : (
          <Toolbar
            disableGutters
            style={{ backgroundColor: "#283223", height: "64px" }}
          >
            <Typography
              variant="h6"
              noWrap
              component="a"
              // href="/"
              sx={{
                mr: 2,
                display: "flex",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
                background:
                  "linear-gradient(45deg, #43ca86 0%,#19d676 50%,#19d676 100%)",
                borderRadius: "20px",
                marginRight: "50px",
                padding: "5px",
              }}
            >
              Smart Menu
            </Typography>
            <Box
              className="dashboard-menu"
              // sx={{
              //   flexGrow: 1,
              //   display: "flex",
              //   justifyContent: "flex-end",
              //   marginRight: "30px",
              // }}
            >
              {pages.map((page) => (
                <Button
                  key={page.name}
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: "white", display: "block" }}
                >
                  {page.show && (
                    <Link
                      className="dashboard-links"
                      to={page.route}
                      target={page.name === "Preview" ? "_blank" : "_self"} // Use "_blank" for "Preview" and "_self" for others
                    >
                      {page.name}
                    </Link>
                  )}
                </Button>
              ))}
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              <span>Welcome {userDetails.company_alias_name}</span>
              <Tooltip title="Open settings">
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenUserMenu}
                  color="inherit"
                  style={{ marginRight: "15px" }}
                >
                  <AccountCircle />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem
                    key={setting.name}
                    component={Link}
                    to={setting.route}
                    onClick={handleCloseUserMenu}
                  >
                    <Typography textAlign="center">{setting.name}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        )}
      </AppBar>
      <div style={{ height: "85vh", overflow: "auto" }}>
        <Outlet />
      </div>
      <footer
        className="app-bar"
        style={{
          display: "flex",
          flexDirection: "row",
          padding: "0px 10px",
          justifyContent: "space-between",
          alignItems: "center",
          height: "40px",
        }}
      >
        <p>Powered By SmartMiddleEast</p>
        <div>
          <a style={{color: "white",cursor:"pointer"}} href={userDetails.facebook} target="_blank">
          <FacebookIcon href={userDetails.facebook} target="_blank"/></a>
          <a style={{ color: "white",cursor:"pointer"}} href={userDetails.instagram} target="_blank"><InstagramIcon /></a>
          <a  style={{ color: "white",cursor:"pointer"}}href={userDetails.twitter} target="_blank"><TwitterIcon/></a>
          {/* <a href={userDetails.tiktok} target="_blank"><Tiktok/></a> */}

        </div>
      </footer>
    </div>
  );
}

export default DashBoard;
