import { useState, useEffect, useContext } from "react";

import { Authentication } from "../utilities/Authentication";
import EditImage from "../Components/EditImage";
import AddImage from "../Components/AddImage";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { userDetails } from "../utilities/RequireAuthentication";
import { envDev } from "../App";

function ImageEntry() {
  // const { userDetails, setUserDetails } = useContext(Authentication);
  const [category, setCategory] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const getCategory = async () => {
    const id = userDetails.id;
    try {
      const response = await fetch(
        `${envDev}/categories/${id}`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });

      const data = await response.json();
      setCategory(data);
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    getCategory();
  }, []);

  const [selectedRow, setSelectedRow] = useState(null);

  const loading = (loadingData) => {
    setLoadingData(loadingData);
  };

  const focusAddImage = (selectedRow) => {
    setSelectedRow(selectedRow);
  };

  return (
    <div>
      <AppBar position="static" className="app-bar">
        <form>
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              sx={{
                display: "flex",
                fontWeight: 600,
                fontSize: "20px",
                textDecoration: "none",
                marginBottom: "10px",
                marginLeft: "15px",
              }}
            >
              Products
            </Typography>
          </Toolbar>
        </form>
      </AppBar>
      <div className="image-container">
        <AddImage
          categories={category}
          selectedRow={selectedRow}
          loading={loading}
        />
        <EditImage categories={category} loadingData={loadingData} loading={loading} focusAddImage={focusAddImage} />
      </div>
    </div>
  );
}
export default ImageEntry;
