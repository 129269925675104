// QuantityInput.jsx
import React from "react";
import { useEffect } from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const QuantityInput = ({ onQuantityChange, productData }) => {
  const [quantity, setQuantity] = React.useState(productData["value"] || 0);
  useEffect(() => {
    setQuantity(productData["value"] || 0);
  }, [productData["id"]]);

  const handleIncrement = () => {
    setQuantity(quantity + 1);
    onQuantityChange({
      quantity: quantity + 1,
      productData,
    });
  };

  const handleDecrement = () => {
    if (quantity > 0) {
      setQuantity(quantity - 1);
      onQuantityChange({
        quantity: quantity - 1,
        productData,
      });
    }
  };

  const handleQuantityChange = (event) => {
    const newQuantity = parseInt(event.target.value, 10);
    setQuantity(newQuantity);
    onQuantityChange({
      quantity: newQuantity,
      productData,
    });
  };
  return (
    <TextField
      required
      disabled
      id="productDetails"
      type="number"
      placeholder="Quantity"
      size="small"
      sx={{ width: "100px" }}
      name="quantity"
      value={quantity}
      onChange={handleQuantityChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">
            <label style={{ fontSize: "16px" }}>{quantity}</label>
            <IconButton
              style={{ fontSize: "14px"}}
              aria-label="increment quantity"
              onClick={handleIncrement}
            >
              <AddIcon />
            </IconButton>
            <IconButton
              style={{ fontSize: "14px", padding: "2px" }}
              aria-label="decrement quantity"
              onClick={handleDecrement}
            >
              <RemoveIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default QuantityInput;
