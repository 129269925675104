import React, { useState } from "react";

import CheckEmail from "../Components/CheckEmail";
import ResetEmail from "../Components/ResetEmail";

import { Paper } from "@mui/material";
import Box from "@mui/material/Box";

const ForgotPwd = () => {
  const [isUser, setUser] = useState(false);
  const [email, setEmail] = useState("");

  const OnResponse = (email) => {
    setEmail(email);
  };
  const OnsetUser = () => {
    setUser(true);
  };

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <div className="new-user-container login_background height">
        <Paper elevation={1} style={{ width: "500px", height: "300px" }}>
          <div style={{ margin: "30px" }}>
            <h3 style={{ textAlign: "center" }}>Forgot Password</h3>
            {!isUser && (
              <CheckEmail
                onCheckEmail={OnResponse}
                onUser={OnsetUser}
              ></CheckEmail>
            )}
            {isUser && <ResetEmail email={email}></ResetEmail>}
          </div>
        </Paper>
      </div>
    </Box>
  );
};
export default ForgotPwd;
