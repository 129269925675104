import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { envDev } from "../App";

function ResetEmail(props) {
  const [password, setPassword] = useState("");
  const [rePassword, setrePassword] = useState("");
  const [errors, setErrors] = useState({});

  const navigate = useNavigate();

  const findFormErrors = () => {
    const newErrors = {};
    if (password.length < 6) {
      newErrors.password = "Minimum 6 Characters";
    } else if (rePassword !== password) {
      newErrors.rePassword = "Password not matched ";
    }
    return newErrors;
  };

  const resetPassword = (e) => {
    e.preventDefault();
    const newErrors = findFormErrors();

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      fetchData();
    }
  };

  const fetchData = async () => {
    try {
      const body = { password };
      const response = await fetch(
        `${envDev}/users/Login/resetPwd/${props.email}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(body),
        }
      );

      const data = response.json;

      alert("Password Restored Sucessfully");
    } catch (err) {
      console.log(err.message);
    }
    navigate("/login");
  };

  return (
    <div>
      <div className="input">
        <label className="label">New Password</label>
        <TextField
          required
          variant="filled"
          type="password"
          autoComplete="current-password"
          onChange={(e) => setPassword(e.target.value)}
          error={!!errors.password}
          helperText={errors.password}
        />
      </div>
      <div className="input">
        <label className="label">Re-Enter Password</label>
        <TextField
          required
          variant="filled"
          type="password"
          onChange={(e) => setrePassword(e.target.value)}
          autoComplete="current-password"
          error={!!errors.rePassword}
          helperText={errors.rePassword}
        />
      </div>
      <div className="btn">
        <Button
          variant="contained"
          className="button-primary"
          style={{ marginLeft: "180px", marginTop: "20px" }}
          onClick={resetPassword}
        >
          Submit
        </Button>
      </div>
    </div>
  );
}
export default ResetEmail;
