import * as React from "react";
import { useState, useEffect, useContext } from "react";
import { Authentication } from "../utilities/Authentication";

import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";

import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { userDetails } from "../utilities/RequireAuthentication";
import { envDev } from "../App";

function EditToolbar(props) {
  const { setRows, setRowModesModel } = props;
}

// Custom cell renderer for displaying images
function ImageCellRenderer(props) {
  // const { userDetails, setUserDetails } = useContext(Authentication);
  const { value } = props;
  return (
      <img
        src={`${envDev}/images/` + userDetails.company + "/" + value}
        alt="Image"
        style={{ maxHeight: "inherit", maxWidth: "inherit" }}
      />
    // <div style={{ display: "flex", justifyContent: "center", width: "100%"}}>
    // </div>
  );
}

function EditImage(props) {
  // const { userDetails, setUserDetails } = useContext(Authentication);

  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);

  const viewData = async () => {
    const id = userDetails.id;
    try {
      const response = await fetch(
        `${envDev}/getimage/${id}`, {
        method: "GET",
      });
      const data = await response.json();
      // When setting rows, add a unique ID to each row
      setRows(data.map((row) => ({ id: row.image_id, ...row })));
    } catch (err) {
      console.error(err.message);
    }
  };

  const deleteImage = async (id) => {
    try {
      const response = await fetch(
        `${envDev}/deleteimage/${id}`, {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
      });

      const data = response.json;
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleEditClick = (id) => () => {
    const selectedRow = rows.find((x) => x.id === id);
    if (selectedRow) {
      // Pass the entire row to focusAddImage
      props.focusAddImage(selectedRow);
    }
  };

  const handleDeleteClick = (id) => () => {
    // deleteImage(id);
    // setRows(rows.filter((row) => row.id !== id));
    const shouldDelete = window.confirm("Are you sure you want to delete?");

    if (shouldDelete) {
      deleteImage(id);
      setRows(rows.filter((row) => row.id !== id));
    } else {
      alert("Deletion canceled.");
    }
  };

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  useEffect(() => {
    viewData();
  }, []);

  useEffect(() => {
    // Handle focus or other actions when `selectedRow` changes.
    if (props.loadingData === true) {
      viewData();
    }
    props.loading(false);
  }, [props.loadingData]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array means this effect runs once on mount

  const columns = [
    !isMobile && {
      field: "image_id",
      headerName: "ImageID",
      flex: 0.15,
      editable: false,
    },
    !isMobile && {
      field: "image_name",
      headerName: "Image",
      flex: 0.25,
      renderCell: (params) => <ImageCellRenderer value={params.value} />,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 0.5,
    },
    !isMobile && {
      field: "menu_type",
      headerName: "Menu Type",
      flex: 0.3,
    },
    {
      field: "categories",
      headerName: "Categories",
      flex: 0.5,
      editable: true,
      renderCell: (params) => {
        const arrayOfObjects = params.value; // Assuming this is the array in your data
        const propertyValues = arrayOfObjects.map(
          (obj) => obj.display_category
        ); // Extract the 'name' property from each object
        const commaSeparatedString = propertyValues.join(", "); // Create a comma-separated string
        return <span>{commaSeparatedString}</span>;
      },
    },

    {
      field: "price",
      headerName: "Price",
      flex: 0.2,
      // editable: true,
    },
    {
      field: "discount_price",
      headerName: "Discount Price",
      flex: 0.2,
      // editable: true,
    },

    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon className="icon" />}
            label="Edit Category"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon style={{ color: "red" }} />}
            label="Delete Image"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  // const filteredColumns = columns.filter((column) => column !== null);

  return (
    <div>
      <span className="heading">
        <strong>Product Details</strong>
      </span>
      <div className="outer-box" style={{ height: "74vh" }}>
        <Box
          className="grid-align"
          sx={{
            "& .actions": {
              color: "text.secondary",
            },
            "& .textPrimary": {
              color: "text.primary",
            },
          }}
        >
          <DataGrid
            rows={rows}
            columns={!isMobile ? columns : columns.filter(Boolean)}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
            processRowUpdate={processRowUpdate}
            getRowId={(row) => row.image_id} // Specify the unique ID property
            experimentalFeatures={{ newEditingApi: true }}
          />
        </Box>
      </div>
    </div>
  );
}

export default EditImage;
