import * as React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { userDetails } from "../utilities/RequireAuthentication";

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { envDev } from "../App";

// import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

const OrderSummary = () => {
  const steps = ["Order Placed", "In Progress", "On Delivery", "Closed"];
  // const { users_id } = useParams(); // Use the useParams hook to get the value of users_id

  // const [userDetailsFromId, setUserDetailsFromId] = useState([]);
  //   const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);

  const [fetchOrderData, setFetchOrderData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [total, setTotal] = useState(0);
  // const [todayDate, setTodayDate] = useState("");

  const [filterData, setfilterData] = useState({
    orderType: "Dine-In",
    orderStatus: "All",
    date: new Date().toISOString().split("T")[0],
  });

  const handleCheckboxChange = () => {
    setfilterData((prevData) => ({
      ...prevData,
      orderType: prevData.orderType === "Dine-In" ? "Online" : "Dine-In",
    }));
  };

  const handleDateChange = (event) => {
    setfilterData((prevData) => ({
      ...prevData,
      date: event.target.value,
    }));
  };

  const handleRadioChange = (event) => {
    setfilterData((prevData) => ({
      ...prevData,
      orderStatus: event.target.value,
    }));
  };

  const deleteProduct = async (data, details_id) => {
    try {
      const response = await fetch(`${envDev}/deleteProduct/${details_id}`, {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
        // body: JSON.stringify({ selectedData }),
      });

      const result = await response.json();
      if (result.success) {
        alert(`Product from the order #${data.id} is deleted Successfully`);
        fetchData();
      } else {
        // Update was not successful
        alert(result.message);
      }

      // getDataOfSelectedCategory(selectedTab.categoryId);
      // alert(`Product from the order #${data.id} is deleted Successfully`);
      // const indexToDelete = data.products.findIndex(
      //   (item) => item.product_id === details_id
      // );

      // // Check if the item with the specified id was found
      // if (indexToDelete !== -1) {
      //   // Use splice to remove the item at the found index
      //   data.products.splice(indexToDelete, 1);
      // }
    } catch (err) {
      console.log(err);
    }
  };

  const handleDeleteClick = (data, details_id) => () => {
    // console.log(history_id, details_id);
    const shouldDelete = window.confirm("Are you sure you want to Delete?");

    if (shouldDelete) {
      deleteProduct(data, details_id);
    }
  };

  const getStatusName = (products) => {
    return products.filter((x) => x.product_status === "In Progress").length > 0
      ? "In Progress"
      : products.filter((x) => x.product_status === "On Delivery").length ===
        products.length
      ? "On Delivery"
      : products.filter((x) => x.product_status === "Closed").length ===
        products.length
      ? "Closed"
      : products.filter((x) => x.product_status === "Closed").length > 0 &&
        products.filter((x) => x.product_status === "Closed").length !==
          products.length
      ? "In Progress"
      : "Order Placed";
  };

  const calculateActiveStep = (products) => {
    const orderStatus = getStatusName(products);
    return orderStatus === "Order Placed"
      ? 0
      : orderStatus === "In Progress"
      ? 1
      : 3;
  };

  const updateData = async (orderStatusDetails) => {
    // console.log("orderStatusDetails", orderStatusDetails);
    try {
      const response = await fetch(`${envDev}/updateorderstatus`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(orderStatusDetails),
      });

      const data = response.json;

      if (response.status === 200) {
        fetchData();
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleSelectChange = (
    event,
    target,
    productIndex,
    orderId,
    product_id
  ) => {
    const { value } = event.target;

    // console.log(orderId, product_id);
    // Find the order in fetchOrderData
    const order = fetchOrderData.find((order) => order.id === orderId);

    if (order) {
      // Find the product in the order
      const product = order.products[productIndex];

      if (product) {
        // Update the product_status
        product.product_status = value;

        // Update fetchOrderData (assuming fetchOrderData is a state variable)
        setFetchOrderData((prevData) => {
          const updatedData = [...prevData];
          const index = updatedData.findIndex((o) => o.id === orderId);

          if (index !== -1) {
            updatedData[index] = { ...order, products: [...order.products] };
          }

          return updatedData;
        });
        const orderStatus = getStatusName(order.products);

        const orderPayload = {
          orderId: orderId,
          productId: product_id,
          orderStatus: orderStatus,
          productStatus: value,
        };
        updateData(orderPayload);
      }
    }
  };

  const fetchData = async () => {
    const id = userDetails.id;
    try {
      const response = await fetch(`${envDev}/orderhistory/${id}`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });

      const data = await response.json();
      setFetchOrderData(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    // getUser();
    fetchData();
  }, []);

  const [activity, setActivity] = useState(true);

  useEffect(() => {
    const resetActivity = () => {
      setActivity(true);
    };

    const handleActivity = () => {
      setActivity(true);

      // Reset the inactivity timeout
      clearTimeout(inactivityTimeout);
      inactivityTimeout = setTimeout(() => {
        // Reload the page after 2 minutes of inactivity
        window.location.reload();
      }, 2 * 60 * 1000);
    };

    let inactivityTimeout;

    // Attach event listeners
    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("keydown", handleActivity);

    // Set initial timeout to check for inactivity
    inactivityTimeout = setTimeout(() => {
      // Reload the page after 2 minutes of inactivity
      window.location.reload();
    }, 2 * 60 * 1000);

    return () => {
      // Clean up event listeners on component unmount
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("keydown", handleActivity);
      clearTimeout(inactivityTimeout);
    };
  }, []);

  useEffect(() => {
    if (fetchOrderData && fetchOrderData.length > 0) {
      // console.log(fetchOrderData);

      const FilterDataByDate = fetchOrderData.filter((item) => {
        // Convert the date_time string to a Date object
        const orderDate = new Date(item.date_time);

        // Format the date as "YYYY-MM-DD"
        const formattedDate = `${orderDate.getFullYear()}-${(
          orderDate.getMonth() + 1
        )
          .toString()
          .padStart(2, "0")}-${orderDate
          .getDate()
          .toString()
          .padStart(2, "0")}`;

        // Compare the formatted date with the filterData.date

        return formattedDate === filterData.date;
      });

      // console.log(FilterDataByDate);
      if (FilterDataByDate.length > 0) {
        // console.log(FilterDataByDate);
        const data = FilterDataByDate.filter(
          (item) => item.order_type === filterData.orderType
        );

        if (filterData.orderStatus !== "All") {
          setFilteredData(
            data.filter((item) => item.status === filterData.orderStatus)
          );
        } else {
          return setFilteredData(data);
        }
      } else {
        return setFilteredData();
      }
    }
  }, [filterData, fetchOrderData]);

  useEffect(() => {
    const total = filteredData?.reduce((acc, item) => acc + item.total, 0) || 0;

    setTotal(total);
  }, [filteredData]);

  return (
    <div>
      <AppBar position="static" className="app-bar">
        <form>
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              sx={{
                display: "flex",
                fontWeight: 600,
                fontSize: "20px",
                textDecoration: "none",
                marginBottom: "10px",
                marginLeft: "15px",
              }}
            >
              Orders
            </Typography>
          </Toolbar>
        </form>
      </AppBar>
      <Paper>
        <div
          style={{
            display: "flex",
            //   alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "row",
            border: "1px solid lightgrey",
          }}
        >
          <div className="input">
            <label
              style={{
                marginRight: "10px",
              }}
            >
              Order Type:
            </label>
            <label className="toggle-button-cover">
              <div id="button-2" className="button r">
                <input
                  className="checkbox"
                  type="checkbox"
                  checked={filterData.orderType === "Online"}
                  onChange={() => handleCheckboxChange()}
                />
                <div className="knobs"></div>
                <div className="layer"></div>
              </div>
            </label>
          </div>
          <div className="input">
            <input
              type="date"
              name="date"
              value={filterData.date}
              onChange={handleDateChange}
              style={{
                width: "150px",
                height: "30px",
                border: "2px solid rgb(145 151 150)",
                borderRadius: "20px",
                padding: "0 10px",
                fontFamily: "unset",
                fontWeight: "600",
              }}
            />
          </div>

          <div className="input">
            <div className="radio-input">
              <label>
                <input
                  value="All"
                  name="orderStatus"
                  id="value-1"
                  type="radio"
                  onChange={handleRadioChange}
                  checked={filterData.orderStatus === "All"}
                />
                <span>All</span>
              </label>
              <label>
                <input
                  value="Order Placed"
                  name="orderStatus"
                  id="value-2"
                  type="radio"
                  onChange={handleRadioChange}
                  checked={filterData.orderStatus === "Order Placed"}
                />
                <span>Order Placed</span>
              </label>
              <label>
                <input
                  value="In Progress"
                  name="orderStatus"
                  id="value-3"
                  type="radio"
                  onChange={handleRadioChange}
                  checked={filterData.orderStatus === "In Progress"}
                />
                <span>In Progress</span>
              </label>
              <label>
                <input
                  value="On Delivery"
                  name="orderStatus"
                  id="value-4"
                  type="radio"
                  onChange={handleRadioChange}
                  checked={filterData.orderStatus === "On Delivery"}
                />
                <span>On Delivery</span>
              </label>
              <label>
                <input
                  value="Closed"
                  name="orderStatus"
                  id="value-5"
                  type="radio"
                  onChange={handleRadioChange}
                  checked={filterData.orderStatus === "Closed"}
                />
                <span>Closed</span>
              </label>
              <span className="selection"></span>
            </div>
          </div>
          <div className="input">
            <label style={{ fontWeight: "600" }}>Total Cost: {total} {userDetails.currency}</label>
          </div>
        </div>
        <div
          style={{ minHeight: filteredData?.length > 0 ? "72.5vh" : "66.7vh" }}
        >
          {filteredData?.length > 0 ? (
            filteredData?.map((data, dataIndex) => (
              <Accordion
                key={dataIndex}
                style={{
                  margin: "10px",
                }}
              >
                {/* {setTotal((prevTotal) => prevTotal + data.total)} */}

                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel-${dataIndex}-content`}
                  id={`panel-${dataIndex}-header`}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      justifyContent: "space-between",
                      alignItems: "center",
                      color: "#555555",
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Typography style={{ marginRight: "5px" }}>
                          <span style={{ fontWeight: "bold" }}># </span>
                          {data.id}
                        </Typography>
                        <Typography
                          style={{
                            backgroundColor:
                              data.order_type === "Online"
                                ? "#ffab00"
                                : "rgb(3 244 128)",
                            //   backgroundColor: "#ffab00",
                            borderRadius: "100px",
                            width: "50px",
                            height: "30px",
                            color: "white",
                            fontSize: "12px",
                            fontWeight: "bold",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {data.order_type}
                        </Typography>
                      </div>
                      <Typography>
                        <span style={{ fontWeight: "bold" }}>
                          {new Date(data.date_time).toLocaleDateString(
                            "en-US",
                            {
                              day: "numeric",
                              month: "short",
                              year: "numeric",
                            }
                          )}
                        </span>
                      </Typography>
                    </div>
                    {data.table_number ? (
                      <Typography>
                        {/* <Chip label="Chip Outlined" variant="outlined" /> */}
                        <span
                          style={{ fontWeight: "bold", marginRight: "3px" }}
                        >
                          Table No:
                        </span>
                        <span
                          style={{
                            padding: "5px 10px",
                            background: "rgb(12 190 145 / 24%)",
                            borderRadius: "30px",
                            color: "black",
                          }}
                        >
                          {data.table_number}
                        </span>
                      </Typography>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "300px",
                          padding: "10px",
                          color: "#3f3f3f",
                          /* border: 1px solid rgb(23, 139, 110); */
                          backgroundColor: "rgb(12 190 145 / 24%)",
                          borderRadius: "10px",
                        }}
                      >
                        <Typography>
                          <span style={{ fontWeight: "bold" }}>Name: </span>
                          {data.customer_name}
                        </Typography>
                        <Typography>
                          <span style={{ fontWeight: "bold" }}>Address: </span>
                          {data.customer_address}
                        </Typography>
                        <Typography>
                          <span style={{ fontWeight: "bold" }}>
                            Phone Number:
                          </span>
                          {data.customer_phonenumber}
                        </Typography>
                      </div>
                    )}
                    <Stepper
                      activeStep={calculateActiveStep(data.products)}
                      alternativeLabel
                      style={{ width: "35%" }}
                    >
                      {steps.map((label) => (
                        <Step key={label}>
                          <StepLabel>{label}</StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-end",
                      }}
                    >
                      <Typography>
                        <span style={{ fontWeight: "bold" }}>
                          {data.total} {userDetails.currency}
                        </span>
                      </Typography>
                    </div>
                  </div>
                </AccordionSummary>

                <AccordionDetails>
                  <table
                    style={{
                      borderCollapse: "collapse",
                      width: "100%",
                    }}
                  >
                    <thead style={{ background: "#15a4808f" }}>
                      <tr>
                        <th
                          style={{
                            border: "1px solid #cacaca",
                            padding: "8px",
                            textAlign: "left",
                          }}
                        >
                          Item
                        </th>
                        <th
                          style={{
                            border: "1px solid #cacaca",
                            padding: "8px",
                            textAlign: "left",
                          }}
                        >
                          Price
                        </th>
                        <th
                          style={{
                            border: "1px solid #cacaca",
                            padding: "8px",
                            textAlign: "left",
                          }}
                        >
                          Quantity
                        </th>
                        <th
                          style={{
                            border: "1px solid #cacaca",
                            padding: "8px",
                            textAlign: "left",
                          }}
                        >
                          Total
                        </th>
                        <th
                          style={{
                            border: "1px solid #cacaca",
                            padding: "8px",
                            textAlign: "left",
                          }}
                        >
                          Status
                        </th>
                        <th
                          style={{
                            border: "1px solid #cacaca",
                            padding: "8px",
                            textAlign: "left",
                          }}
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.products?.map((product, productIndex) => (
                        <tr key={productIndex}>
                          <td
                            style={{
                              border: "1px solid #cacaca",
                              padding: "8px",
                              textAlign: "left",
                            }}
                          >
                            {product.product_name}
                          </td>
                          <td
                            style={{
                              border: "1px solid #cacaca",
                              padding: "8px",
                              textAlign: "left",
                            }}
                          >
                            {product.price} {userDetails.currency}
                          </td>
                          <td
                            style={{
                              border: "1px solid #cacaca",
                              padding: "8px",
                              textAlign: "left",
                            }}
                          >
                            {product.quantity}
                          </td>
                          <td
                            style={{
                              border: "1px solid #cacaca",
                              padding: "8px",
                              textAlign: "left",
                            }}
                          >
                            {product.price * product.quantity} {userDetails.currency}
                          </td>
                          {product.product_status === "Closed" ? (
                            <td
                              style={{
                                border: "1px solid #cacaca",
                                padding: "8px",
                                textAlign: "left",
                              }}
                            >
                              <label style={{ padding: "10px" }}>Closed</label>
                            </td>
                          ) : (
                            <td
                              style={{
                                border: "1px solid #cacaca",
                                padding: "8px",
                                textAlign: "left",
                              }}
                            >
                              <Select
                                required
                                placeholder="Order Status"
                                variant="filled"
                                sx={{ width: 200, height: "40px" }}
                                value={product.product_status}
                                name="product_status"
                                onChange={(event) =>
                                  handleSelectChange(
                                    event,
                                    "product_status",
                                    productIndex,
                                    data.id,
                                    product.product_id
                                  )
                                }
                              >
                                <MenuItem value="Order Placed">
                                  Order Placed
                                </MenuItem>
                                <MenuItem value="In Progress">
                                  In Progress
                                </MenuItem>
                                <MenuItem value="On Delivery">
                                  On Delivery
                                </MenuItem>
                                <MenuItem value="Closed">Closed</MenuItem>
                              </Select>
                            </td>
                          )}

                          <td
                            style={{
                              border: "1px solid #cacaca",
                              padding: "8px",
                              textAlign: "left",
                            }}
                          >
                            <DeleteIcon
                              style={{
                                color:
                                  product.product_status === "On Delivery" ||
                                  product.product_status === "In Progress" ||
                                  product.product_status === "Closed"
                                    ? "#80808096"
                                    : "red",
                                cursor: "pointer",
                                pointerEvents:
                                  product.product_status === "On Delivery" ||
                                  product.product_status === "In Progress" ||
                                  product.product_status === "Closed"
                                    ? "none"
                                    : "auto",
                              }}
                              onClick={handleDeleteClick(
                                data,
                                product.product_id
                              )}
                              // disabled={product.product_status === 'In Progress' || product.product_status === 'Closed'}
                            />
                          </td>
                          {/* Add more columns as needed */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </AccordionDetails>
              </Accordion>
            ))
          ) : (
            <p
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                fontWeight: "bold",
                height: "66.5vh",
              }}
            >
              No Orders Found
            </p>
          )}
        </div>
      </Paper>
    </div>
  );
};

export default OrderSummary;
