import { Navigate } from "react-router-dom";
import { envDev } from "../App";

export let userDetails = localStorage.getItem("userDetails")
  ? JSON.parse(localStorage.getItem("userDetails"))
  : {};

// Setter function to update userDetails
export function setUserDetails(newValue) {
  userDetails = newValue;
  // Optionally, update localStorage as well
  localStorage.setItem("userDetails", JSON.stringify(newValue));
}

export const RequireAuthentication = ({ children }) => {
  // const { userDetails, setUserDetails } = useContext(Authentication);
  const checkIfUserIsValid = async () => {
    try {
      const response = await fetch(
        `${envDev}/checkuserauth?username=${user.username}&pwd=${user.pwd}`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      );

      const data = await response.json();
      if (!data.isValid) {
        return <Navigate to="/login" />;
      } else {
        return children;
      }
    } catch (err) {
      console.log(err.message);
    }
  };
  let user = localStorage.getItem("userDetails");
  if (user) {
    user = JSON.parse(user);
    // setUserDetails(user);
  }

  if (!user?.username) {
    return <Navigate to="/login" />;
  } else {
    // console.log(user.image_name, user);
    // const link = document.querySelector("link[rel~='icon']");
    // const og = document.querySelector("meta[property~='og:image']");

    // if (link !== null) {
    //   // Assuming envDev contains the base URL
    //   const faviconUrl = `${envDev}/images/${user.company}/${user.image_name}`;
    //   console.log(faviconUrl);
    //   // Update favicon href
    //   link.href = faviconUrl;

    //   if (og !== null) {
    //     // Update Open Graph image content
    //     og.content = faviconUrl;
    //   } else {
    //     // Handle case where og:image meta tag is not found
    //     console.error("Open Graph image meta tag not found.");
    //   }
    // } else {
    //   // Handle case where favicon link is not found
    //   console.error("Favicon link not found.");
    // }

    checkIfUserIsValid();
  }
  return children;
};
