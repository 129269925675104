import React from "react";
import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";

import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import QuantityInput from "../Components/QuantityInput";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { envDev } from "../App";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import Chip from "@mui/material/Chip";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import Drawer from "@mui/material/Drawer";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import DiscountIcon from "@mui/icons-material/Discount";

const PreviewTable = () => {
  const { guid } = useParams(); // Use the useParams hook to get the value of users_id
  // const { users_id } = useParams(); // Use the useParams hook to get the value of users_id

  const [setting, setSetting] = useState({});
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);

  // const [category, setCategory] = useState([]);

  const [tabDetails, setTabDetails] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [openCart, setOpenCart] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);
  const [validation, setValidation] = useState({
    tableNumber: true,
    // menuType: true,
    // productDescription: true,
    comments: true,
  });

  const [filterMenuType, setFilterMenuType] = useState([]);
  const [filterData, setFilterData] = useState("ALL");
  const [filteredData, setFilteredData] = useState([]);
  const initialValues = {
    client_id: "",
    company: "",
    orderType: "Dine-In",
    tableNumber: "",
    status: "Order Placed",
    dateTime: new Date(),
    comments: "No Comments",
    totalCost: 0,
    productDetails: [], // <-- Change this property name
  };

  const [dineIn, setDineIn] = useState(initialValues);
  const [open, setOpen] = React.useState(false);
  const [forceRender, setForceRender] = useState(false);

  const getUser = async () => {
    try {
      const response = await fetch(`${envDev}/getuserbyguid/${guid}`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });

      const data = await response.json();
      if (data.length) {
        setDineIn((prevData) => ({
          ...prevData,
          client_id: data[0].id,
          company: data[0].company,
          companyAliasName:data[0].company_alias_name,
          // clientMobileNumber: data.rows[0].whatsapp_number,
          facebook: data[0].facebook,
          twitter: data[0].twitter,
          instagram: data[0].instagram,
          currency:data[0].currency,
        }));
        await getSetting(data[0]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getSetting = async (userData) => {
    try {
      const response = await fetch(`${envDev}/getsetting/${userData.id}`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });

      const data = await response.json();

      const transformedData = data.reduce((acc, curr) => {
        if (!acc.category_id) {
          // If the accumulator is empty, set initial values
          acc.category_id = curr.category_id;
          acc.description = curr.description;
          acc.show_text = curr.show_text;
          acc.back_colour = curr.back_colour;
          acc.text_colour = curr.text_colour;
          acc.animation = curr.animation;
          acc.transparent = curr.transparent;
          acc.speed = curr.speed;
          acc.image_names = [curr.image_name];
          acc.selected_category = curr.selected_category;
        } else {
          // If accumulator already has values, add image_name to the array
          acc.image_names.push(curr.image_name);
        }
        return acc;
      }, {});

      setSetting(transformedData);
      getDataOfSelectedCategory(transformedData.selected_category, userData.id);
    } catch (err) {
      console.log(err);
    }
  };

  const getDataOfSelectedCategory = async (selectedCategory, userId) => {
    try {
      const response = await fetch(
        `${envDev}/getdataofselectedcategory?selectedCategory_id=${selectedCategory}&users_id=${userId}`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      );

      const data = await response.json();
      const updatedProductDetails = data.filter((x) => x.show_image);
      // console.log(data);
      setTabDetails(updatedProductDetails);
      setProductQuantities(
        updatedProductDetails.reduce((acc, data) => {
          acc[data.id] = 0; // initialize quantity to 0
          return acc;
        }, {})
      );
    } catch (err) {
      console.log(err);
    }
  };

  const sendDineIn = async () => {
    toggleDrawer(false);
    try {
      const response = await fetch(`${envDev}/orderDetails`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dineIn),
      });
      if (response.ok) {
        setProductQuantities({});
        // The message was sent successfully
        const responseData = await response.json();

        // You can use the responseData to determine the success status
        if (responseData.success) {
          // Display a success message in the UI
          // alert(responseData.message);
          setOpen(true);
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        } else {
          // Display an error message in the UI
          alert(responseData.error);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };
  const handleTabChange = (event, index) => {
    setSelectedTabIndex(index);
    return setFilterData(index ? filterMenuType[index - 1] : "ALL");
  };

  const [productQuantities, setProductQuantities] = useState(
    filteredData.reduce((acc, data) => {
      acc[data.id] = 0; // initialize quantity to 0
      return acc;
    }, {})
  );

  const handleQuantityChange = ({ quantity, productData }) => {
    console.log(productQuantities);
    const id = productData.id;
    setProductQuantities((prevQuantities) => ({
      ...prevQuantities,
      [id]: quantity || 0,
    }));
    setForceRender((prev) => !prev);
  };

  const toggleDrawer = (open) => (event) => {
    // Check if dineIn is empty
    if (Object.keys(dineIn).length === 0) {
      alert("Please add products to your order.");
      return; // Prevent submission
    }

    // Check if productDetails is empty
    if (dineIn.productDetails.length === 0) {
      alert("Please add at least one product to your order.");
      return; // Prevent submission
    }
    if (!dineIn.tableNumber || !dineIn.comments) {
      alert("Please enter all the required details.");
      return; // Prevent submission
    }

    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpenCart(open);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setDineIn((prev) => {
      const data = { ...prev, [name]: value };
      setValidation((prevValidation) => ({
        ...prevValidation,
        [name]: !!value,
      }));
      return data;
    });
  };

  const reset = () => {
    setDineIn(initialValues);
  };

  const handleSubmit = (e) => {
    toggleDrawer(true);

    const shouldSend = window.confirm("Are you sure you want place order?");

    if (shouldSend) {
      console.log(dineIn);
      sendDineIn();
      reset();
    } else {
      alert("Order placement is cancelled.");
    }
  };

  useEffect(() => {
    const prodDetails = [];
    let updatedTotalCost = 0;
    let disAmount = 0;

    setSelectedProducts(
      tabDetails.filter((prod) => {
        if (productQuantities[prod.id] > 0) {
          const actualPrice =
            prod.discount_price > 0 ? prod.discount_price : prod.price;
          disAmount =
            prod.discount_price > 0
              ? disAmount +
                (prod.price - prod.discount_price) * productQuantities[prod.id]
              : disAmount;
          prodDetails.push({
            name: prod.name,
            price: actualPrice,
            quantity: productQuantities[prod.id],
            status: "Order Placed",
          });
          updatedTotalCost += actualPrice * productQuantities[prod.id];
          return prod;
        }
      })
    );
    setDiscountAmount(Math.round(disAmount).toFixed(2));
    setDineIn((prevData) => ({
      ...prevData,
      totalCost: updatedTotalCost,
      productDetails: prodDetails,
    }));
    if (!updatedTotalCost) {
      setOpenCart(false);
    }
  }, [productQuantities]);

  useEffect(() => {
    const fetchData = async () => {
      await getUser();
    };

    fetchData();
  }, []);

  useEffect(() => {
    const uniqueMenuTypesSet = new Set(
      tabDetails.map((item) => item.menu_type)
    );
    const distinctMenuTypesArray = Array.from(uniqueMenuTypesSet);
    // Now, separatedMenu is an object where keys are unique menu_types
    setFilterMenuType(distinctMenuTypesArray);
  }, [tabDetails]);

  useEffect(() => {
    // console.log(filterData, tabDetails);

    if (tabDetails && tabDetails.length > 0) {
      if (filterData === "ALL") {
        return setFilteredData(tabDetails);
      } else {
        const data = tabDetails.filter((item) => item.menu_type === filterData);
        // console.log(data);
        return setFilteredData(data);
      }
    }
  }, [filterData, tabDetails]);

  useEffect(() => {
    // Your logic to handle the external state change
  }, [forceRender]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array means this effect runs once on mount

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <div>
      <AppBar position="static" className="app-bar">
        <form>
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              sx={{
                display: "flex",
                fontWeight: 600,
                fontSize: "20px",
                textDecoration: "none",
                marginBottom: "10px",
                marginLeft: "15px",
              }}
            >
              {dineIn.companyAliasName}
            </Typography>
          </Toolbar>
        </form>
      </AppBar>
      <Box
        sx={{
          flexGrow: 1,
          // maxWidth: { xs: 320, sm: 480 },
          width: "100%",
          bgcolor: "background.paper",
        }}
      >
        <Tabs
          textColor="inherit"
          indicatorColor="inherit"
          value={selectedTabIndex}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="visible arrows tabs example"
          sx={{
            [`& .${tabsClasses.scrollButtons}`]: {
              "&.Mui-disabled": { opacity: 0.3 },
            },
            color: "#F00",
            backgroundColor: "#ffb7792b",
            fontWeight: "600",
          }}
        >
          <Tab label="All" style={{ fontWeight: "700" }} />

          {filterMenuType &&
            filterMenuType.length > 0 &&
            filterMenuType.map((data, index) => (
              <Tab label={data} style={{ fontWeight: "700" }} />
            ))}
        </Tabs>
      </Box>

      <Grid
        style={{
          overflowY: "auto",
          height: isMobile ? "77.4vh" : "72.5vh",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            margin: "5px",
            flexWrap: "wrap",
            minHeight: "52.5vh",
          }}
        >
          {!filteredData.length ? (
            <Typography
              component="div"
              sx={{ position: "absolute", top: "50%" }}
            >
              -- No Products Found --
            </Typography>
          ) : (
            filteredData.map((data, index) => (
              <Card
                key={index}
                style={{
                  display: "flex",
                  width: isMobile ? "100%" : "32.5%",
                  height: "150px",
                  margin: "5px",
                  borderRadius: "2px",
                  background: "#eeeeee6e",
                  boxShadow:
                    "rgb(190, 190, 190) 0px 0px 1px, rgb(255, 255, 255) 1px 1px 1px",
                  border: "1px solid rgb(213, 213, 213)",
                }}
              >
                <CardMedia
                  component="img"
                  sx={{
                    width: isMobile ? "150px" : "200px",
                    height: "150px",
                    padding: "1px",
                    borderRadius: "1px",
                    objectFit: "fill"
                  }}
                  src={
                    `${envDev}/images/` +
                    dineIn.company +
                    "/" +
                    data.image_name
                  }
                  alt="loading"
                  title="ProductImage"
                />
                <CardContent
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    width: "100%",
                  }}
                >
                  <Typography
                    component="div"
                    sx={{
                      fontSize: "17px",
                      fontWeight: "600",
                      color: "#505050",
                    }}
                  >
                    {data.name}
                  </Typography>

                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {data.discount_price > 0 ? (
                      <Typography style={{ fontSize: "17px" }}>
                        <span
                          style={{
                            textDecoration: "line-through",
                            color: "#f92323",
                            fontWeight: "600",
                            marginRight: "4px",
                          }}
                        >
                          {data.price}
                        </span>
                        <span>{data.discount_price} {dineIn.currency}</span>
                      </Typography>
                    ) : (
                      <Typography
                        style={{ fontSize: "17px", color: "#413f3f" }}
                      >
                        <span>{data.price} {dineIn.currency}</span>
                      </Typography>
                    )}
                    <CardActions
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        gap: "10px",
                      }}
                    >
                      <QuantityInput
                        onQuantityChange={handleQuantityChange}
                        key={forceRender}
                        productData={{
                          price:
                            data.discount_price > 0
                              ? data.discount_price
                              : data.price,
                          name: data.name,
                          id: data.id,
                          value: productQuantities[data.id],
                        }}
                      />
                    </CardActions>
                  </div>
                </CardContent>
              </Card>
            ))
          )}
        </div>
        <div
          style={{
            margin: "10px",
            border: "1px solid gainsboro",
            background: "rgb(12 190 145 / 8%)",
            padding: "5px",
          }}
        >
          <p
            style={{
              margin: "5px",
              color: "#3c3b3a",
              fontWeight: "600",
            }}
          >
            Enter Details:
          </p>
          <div
            style={{
              display: "flex",
              width: "100%",
              flexWrap: "wrap",
            }}
          >
            <TextField
              required
              id="Table Number"
              placeholder="Table Number"
              variant="filled"
              size="small"
              sx={{ width: isMobile ? "100%" : "14%", margin: "5px" }}
              // sx={{ padding: "0px 10px 10px 0px" }}
              onChange={handleChange}
              name="tableNumber"
              value={dineIn.tableNumber}
              error={!validation.tableNumber}
              helperText={!validation.tableNumber && "Table number is required"}
            />

            <TextField
              required
              multiline
              id="comments"
              placeholder="Special Instructions"
              variant="filled"
              size="small"
              sx={{ width: isMobile ? "100%" : "84%", margin: "5px" }}
              onChange={handleChange}
              name="comments"
              value={dineIn.comments}
            />
          </div>
        </div>
      </Grid>
      <div
        className="fixed-bottom"
        style={{
          // width: "100%",
          textAlign: "center",
          padding: "10px",
          borderRadius: "3px",
          borderTop: "1px solid #d6d2d2",
          // Add any additional styling you need
        }}
      >
        <label
          style={{
            display: "flex",
            justifyContent: isMobile ? "space-between" : "flex-end",
            margin: "0px 10px",
            fontWeight: 600,
            fontSize: "20px",
            padding: "0px 10px",
          }}
        >
          <Chip
            icon={<ShoppingCartIcon />}
            label="View Cart"
            style={{
              backgroundColor: "#ffb779",
              marginRight: "10px",
              fontWeight: "500",
              padding: "9px 5px",
            }}
            onClick={toggleDrawer(true)}
          />

          <span style={{ fontWeight: "600" }}>
            Total: {dineIn.totalCost} {dineIn.currency}
          </span>
        </label>
      </div>

      <Drawer anchor="right" open={openCart} onClose={toggleDrawer(false)}>
        <div
          style={{
            margin: "10px 10px 0 10px",
            overflowY: "auto",
            backgroundColor: "rgb(239 239 239 / 72%)",
            height: "100vh",
            borderRadius: "5px",
            width: isMobile ? "96vw" : "inherit",
          }}
        >
          <div
            style={{
              margin: "20px",
              fontSize: "16px",
              fontWeight: "700",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <KeyboardDoubleArrowLeftIcon
              style={{ cursor: "pointer" }}
              onClick={toggleDrawer(false)}
            ></KeyboardDoubleArrowLeftIcon>
            <span style={{ marginLeft: "5px" }}>Order Details</span>
          </div>
          {discountAmount && (
            <div
              style={{
                display: "flex",
                backgroundColor: "#ccffe6",
                color: "green",
                padding: "10px",
                borderRadius: "10px",
                margin: "12px",
                alignItems: "center",
                border: "1px solid",
              }}
            >
              <DiscountIcon />
              <span style={{ marginLeft: "5px" }}>{discountAmount} {dineIn.currency} </span>
              <span style={{ marginLeft: "3px" }}>saved on this order</span>
            </div>
          )}

          <div
            style={{
              backgroundColor: "white",
              borderRadius: "10px",
              padding: "10px 0px",
              margin: "12px",
              fontSize: "14px",
              border: "1px solid #d0d0d0",
            }}
          >
            {selectedProducts?.length &&
              selectedProducts.map((data, index) => (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "10px",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      marginRight: "10px",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      width: "50%",
                    }}
                  >
                    {data.name}
                  </span>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <QuantityInput
                      onQuantityChange={handleQuantityChange}
                      productData={{
                        price: data.price,
                        name: data.name,
                        id: data.id,
                        value: productQuantities[data.id],
                      }}
                    />
                    <div style={{ width: "90px" }}>
                      {data.discount_price > 0 &&
                      data.price > data.discount_price ? (
                        <Typography
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            fontSize: "14px",
                            marginLeft: "10px",
                          }}
                        >
                          <span
                            style={{
                              textDecoration: "line-through",
                              color: "#f92323",
                              fontWeight: "600",
                              fontSize: "12px",
                            }}
                          >
                            {data.price * productQuantities[data.id]}
                          </span>
                          <span>
                            {data.discount_price * productQuantities[data.id]} {dineIn.currency}
                          </span>
                        </Typography>
                      ) : (
                        <Typography
                          style={{
                            fontSize: "14px",
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "10px",
                            justifyContent: "center",
                          }}
                        >
                          <span>
                            {data.price * productQuantities[data.id]} {dineIn.currency}
                          </span>
                        </Typography>
                      )}
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <div
            style={{ margin: "10px 20px", fontSize: "16px", fontWeight: "700" }}
          >
            Delivery Details
          </div>
          <div
            style={{
              backgroundColor: "white",
              borderRadius: "10px",
              padding: "10px 0px",
              display: "flex",
              flexDirection: "column",
              margin: "12px",
              border: "1px solid #d0d0d0",
              fontSize: "14px",
            }}
          >
            <span style={{ marginLeft: "10px", marginBottom: "5px" }}>
              <span style={{ fontWeight: "500" }}>Table Number: </span>
              {dineIn.tableNumber}
            </span>
            <span style={{ marginLeft: "10px", marginBottom: "5px" }}>
              <span style={{ fontWeight: "500" }}>Special Instructions: </span>
              {dineIn.comments}
            </span>
          </div>
        </div>
        <div
          style={{
            backgroundColor: "rgb(239 236 236)",
            padding: "15px",
            display: "flex",
            margin: "12px",
            border: "1px solid #d0d0d0",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Chip
            label="Place Order"
            style={{
              fontWeight: "700",
              padding: "10px",
              backgroundColor: "#ffb779",
              fontSize: "16px",
              border: "1px solid rgb(188 184 184)",
            }}
            onClick={handleSubmit}
          />

          <span style={{ fontWeight: "600", fontSize: "16px" }}>
            Total: {dineIn.totalCost} {dineIn.currency}
          </span>
        </div>
      </Drawer>
      <footer
        className="app-bar"
        style={{
          display: "flex",
          flexDirection: "row",
          padding: "0px 10px",
          justifyContent: "space-between",
          alignItems: "center",
          height: "40px",
        }}
      >
        <p>Powered By SmartMiddleEast</p>
        <div>
          <a style={{color: "white",cursor:"pointer"}} href={dineIn.facebook} target="_blank">
          <FacebookIcon href={dineIn.facebook} target="_blank"/></a>
          <a style={{ color: "white",cursor:"pointer"}} href={dineIn.instagram} target="_blank"><InstagramIcon /></a>
          <a  style={{ color: "white",cursor:"pointer"}}href={dineIn.twitter} target="_blank"><TwitterIcon/></a>
          {/* <a href={dineIn.tiktok} target="_blank"><Tiktok/></a> */}

        </div>
      </footer>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          vertical="top"
          horizontal="center"
          TransitionComponent="SlideTransition"
          direction="left"
          sx={{ width: "100%" }}
        >
          Order has been placed successfully!
        </Alert>
      </Snackbar>
    </div>
  );
};

export default PreviewTable;
