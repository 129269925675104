import { Route, Routes } from "react-router-dom";

import DashBoard from "./Pages/DashBoard";
import ForgotPwd from "./Pages/ForgotPwd";
import Login from "./Pages/Login";
import Home from "./Pages/Home";
import List from "./Pages/List";
import ImageEntry from "./Pages/ImageEntry";
import Category from "./Pages/Category";
import Preview from "./Pages/Preview";
import Profile from "./Pages/Profile";
import PreviewList from "./Pages/PreviewList";
import PreviewTable from "./Pages/PreviewTable";
import OrderSummary from "./Pages/OrderSummary";
import Admin from "./Pages/Admin";
import { Helmet } from 'react-helmet';

import "./App.scss";

import { AuthProvider } from "./utilities/Authentication";
import { RequireAuthentication } from "./utilities/RequireAuthentication";
export const envDev = "";
const App = () => {
  return (
    <div>
      <Helmet>
        <meta property="og:title" content="Smart Menu" />
        <meta property="og:description" content="This is a sample smart menu test" />
        <meta property="og:image" content="https://www.souqmarketplace.com/images/smartprotox/banner-1709804983403.jpg" />
      </Helmet>
      <AuthProvider>
        <Routes>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/forgotpwd" element={<ForgotPwd />} />
          <Route path="/preview/:users_id" element={<Preview />} />
          <Route path="/previewlist/:guid" element={<PreviewList />} />
          <Route path="/previewtable/:guid" element={<PreviewTable />} />
          <Route path="/admin2024" element={<Admin />} />
          <Route
            path="/"
            element={
              <RequireAuthentication>
                <DashBoard />
              </RequireAuthentication>
            }
          >
            <Route path="/home" element={<Home />} />
            <Route path="/list" element={<List />}></Route>
            <Route path="/imageentry" element={<ImageEntry />} />
            <Route path="/category" element={<Category />} />
            <Route path="/ordersummary" element={<OrderSummary />} />
            <Route path="/profile" element={<Profile />} />
          </Route>
        </Routes>
      </AuthProvider>
    </div>
  );
};

export default App;
