import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import React, { useEffect, useState } from "react";

import { Button } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { envDev } from "../App";
import { userDetails } from "../utilities/RequireAuthentication";

function List() {
  const [category, setCategory] = useState([]);
  const [selectedTab, setSelectedTab] = useState([]);

  const [tabDetails, setTabDetails] = useState([]);
  const [updatedRows, setUpdatedRows] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const [showTabs, setShowTabs] = useState(false);
  const [isTabview, setTabView] = useState(window.innerWidth <= 1024);

  // const handleToggleTabs = () => {
  //   setShowTabs((prevShowTabs) => !prevShowTabs);
  // };

  const handleTabClick = (cid, cname) => {
    setSelectedTab({
      categoryId: cid,
      categoryName: cname,
    });
    getDataOfSelectedCategory(cid);
  };

  const handleChange = (id, event) => {
    // Update the checkbox state in tabDetails
    const updatedTabDetails = tabDetails.map((data) => {
      if (data.id === id) {
        return {
          ...data,
          show_image: event.target.checked,
        };
      }
      return data;
    });

    setTabDetails(updatedTabDetails);

    // Track the updated row
    const updatedRow = updatedRows.find((row) => row.id === id);
    if (!updatedRow) {
      setUpdatedRows([
        ...updatedRows,
        { id, show_image: event.target.checked },
      ]);
    } else if (updatedRow.show_image !== event.target.checked) {
      // If the state has changed, update the row
      setUpdatedRows((prev) =>
        prev.map((row) =>
          row.id === id ? { id, show_image: event.target.checked } : row
        )
      );
    }
  };

  const updateShowImage = async (updatedRows) => {
    try {
      const response = await fetch(`${envDev}/showimage/${userDetails.id}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(updatedRows),
      });

      if (response.status === 200) {
        alert("uploaded Sucessfully");
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleSubmit = () => {
    updateShowImage(updatedRows);
  };

  const getCategory = async () => {
    try {
      const response = await fetch(`${envDev}/categories/${userDetails.id}`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });

      const data = await response.json();
      setCategory(data);
      if (data.length > 0) {
        setSelectedTab({
          categoryId: data[0].category_id,
          categoryName: data[0].display_category,
        });
        getDataOfSelectedCategory(data[0].category_id);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  const getDataOfSelectedCategory = async (selectedCategory_id) => {
    const users_id = userDetails.id; // Your second parameter

    try {
      const response = await fetch(
        `${envDev}/getdataofselectedcategory?selectedCategory_id=${selectedCategory_id}&users_id=${users_id}`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      );

      const data = await response.json();
      setTabDetails(data);
    } catch (err) {
      console.log(err.message);
    }
  };

  const Remove = async (selectedData) => {
    try {
      const response = await fetch(
        `${envDev}/deletecategoryimage/${selectedData.image_id}`,
        {
          method: "DELETE",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ selectedData }),
        }
      );

      getDataOfSelectedCategory(selectedTab.categoryId);
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    getCategory();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
      setTabView(window.innerWidth <= 1024);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array means this effect runs once on mount

  return (
    <div>
      <AppBar position="static" className="app-bar">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            sx={{
              display: "flex",
              fontWeight: 600,
              fontSize: "20px",
              textDecoration: "none",
              marginBottom: "10px",
              marginLeft: "15px",
            }}
          >
            {selectedTab.categoryName}
          </Typography>
          {isMobile || isTabview ? (
            <button
              className="tabs-container"
              style={{
                backgroundColor: "#178b6e",
                border: "none",
                marginTop: "10px",
                // height: "45px",
                color: "white",
                fontSize: "20px",
                fontWeight: 600,
              }}
              onClick={() => setShowTabs(!showTabs)}
            >
              Categories
              {showTabs && (
                <div className="tabs">
                  <ul>
                    {category.map((item) => (
                      <li
                        key={item.category_id}
                        className={`tab ${
                          selectedTab === item.category_id ? "active" : ""
                        }`}
                        onClick={() =>
                          handleTabClick(
                            item.category_id,
                            item.display_category
                          )
                        }
                      >
                        {item.display_category}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </button>
          ) : (
            <div className="tabs-container">
              <ul className="tabs">
                {category.map((item) => (
                  <li
                    key={item.category_id}
                    className={`tab ${
                      selectedTab === item.category_id ? "active" : ""
                    }`}
                    onClick={() =>
                      handleTabClick(item.category_id, item.display_category)
                    }
                  >
                    {item.display_category}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </Toolbar>
      </AppBar>
      <div style={{ height: "77.1vh" }}>
        <Grid
          style={{
            display: "flex",
            flexWrap: "wrap",
            margin: "5px auto",
          }}
        >
          {!tabDetails.length ? (
            <Typography
              component="div"
              sx={{ position: "absolute", top: "50%", left: "45%" }}
            >
              -- No Products Found --
            </Typography>
          ) : (
            tabDetails.map((data) => (
              <Card
                style={{
                  display: "flex",
                  margin: "5px",
                  border: "1px solid #afadad",
                  background: "#fdfdfd",
                  width: isMobile ? "100%" : isTabview ? "48%" : "24%",
                }}
              >
                <CardMedia
                  component="img"
                  sx={{
                    width: "150px",
                    height: "150px",
                    border: "1px",
                    borderRadius: "2px",
                    boxShadow: "0px 0px 1px 1px rgb(107 107 107)",
                    objectFit: "unset !important",
                  }}
                  src={
                    `${envDev}/images/` +
                    userDetails.company +
                    "/" +
                    data.image_name
                  }
                  // src={"/images/" + data.image_name}
                  alt="loading"
                  title="ProductImage"
                />
                <CardContent
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    padding: "5px",
                  }}
                >
                  <Typography sx={{ fontWeight: "600", fontSize: "16px" }}>
                    {data.name}
                  </Typography>
                  {data.discount_price > 0 ? (
                    <Typography style={{ fontSize: "15px" }}>
                      <span
                        style={{
                          textDecoration: "line-through",
                          paddingRight: "5px",
                          color: "#e1492a",
                        }}
                      >
                        {data.price}
                      </span>
                      <span>{data.discount_price} {userDetails.currency}</span>
                    </Typography>
                  ) : (
                    <Typography style={{ fontSize: "15px" }}>
                      <span>{data.price} {userDetails.currency}</span>
                    </Typography>
                  )}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Checkbox
                      name="show_image"
                      onChange={(event) => handleChange(data.id, event)}
                      checked={data.show_image}
                    />
                    <DeleteIcon
                      sx={{
                        color: "red",
                      }}
                      onClick={() => {
                        Remove(data);
                      }}
                    >
                      Remove
                    </DeleteIcon>
                  </div>
                </CardContent>
                {/* <CardActions
                    
                  >
                    <label>Show</label>
                  </CardActions> */}
              </Card>
            ))
          )}
        </Grid>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            margin: "10px",
          }}
        >
          {tabDetails.length === 0 ? (
            ""
          ) : (
            <Button
              className="button-primary"
              sx={{
                color: "white",
                width: 100,
                alignContent: "center",
                marginBottom: "10px",
              }}
              onClick={() => {
                handleSubmit();
              }}
            >
              Submit
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
export default List;
