import TextField from "@mui/material/TextField";
import { useState } from "react";
import Button from "@mui/material/Button";
import { envDev } from "../App";

const Admin = () => {
  const initialValues = {
    guid: "",
    company: "",
    companyAliasName:"",
    username: "",
    pwd: "",
    land_number: "",
    mobile_number: "",
    whatsapp_number: "",
    email: "",
    contact_person: "",
    image_qty: "",
    category_qty: "",
    location: "",
    sales_staff: "",
    booking_date: "",
    reference_number: "",
    city: "",
    address: "",
    facebook: "",
    twitter: "",
    instagram: "",
    tiktok:"",
  };

  const [newUser, setNewUser] = useState(initialValues);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setNewUser((prev) => {
      const data = { ...prev, [name]: value };

      return data;
    });
  };

  const createNewUser = async () => {
    try {
      const generateGuid = () => {
        const characters = "abcdefghijklmnopqrstuvwxyz0123456789";
        let guid = "";
        for (let i = 0; i < 10; i++) {
          const randomIndex = Math.floor(Math.random() * characters.length);
          guid += characters.charAt(randomIndex);
        }
        return guid;
      };

      // const guid = generateGuid();
      newUser.guid = generateGuid();
      const response = await fetch(`${envDev}/newuser`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(newUser),
      });

      if (response.status === 200) {
        alert("New User Created Sucessfully");
        setNewUser(initialValues);
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const shouldUpload = window.confirm(
      "Are you sure you want to create new user?"
    );

    if (shouldUpload) {
      createNewUser();
    } else {
      alert("User creation is aborted by admin");
    }
  };
  return (
    <div
      style={{ display: "flex", alignItems: "center", flexDirection: "column" }}
    >
      <h2>User Details</h2>

      <div style={{ display: "flex", alignItems: "center" }}>
        <div>
          <div className="input">
            <label className="label">Company</label>
            <TextField
              required
              variant="filled"
              size="small"
              placeholder="CNS PVT LTD"
              sx={{ padding: "0px 10px 10px 0px" }}
              onChange={handleChange}
              name="company"
              value={newUser.company}
            />
          </div>
          <div className="input">
            <label className="label">Company Alias Name</label>
            <TextField
              required
              variant="filled"
              size="small"
              placeholder="CNS PVT LTD"
              sx={{ padding: "0px 10px 10px 0px" }}
              onChange={handleChange}
              name="companyAliasName"
              value={newUser.companyAliasName}
            />
          </div>
          <div className="input">
            <label className="label">User Name</label>
            <TextField
              required
              variant="filled"
              size="small"
              placeholder="cns"
              sx={{ padding: "0px 10px 10px 0px" }}
              onChange={handleChange}
              name="username"
              value={newUser.username}
            />
          </div>
          <div className="input">
            <label className="label">Password</label>
            <TextField
              required
              type="password"
              variant="filled"
              size="small"
              sx={{ padding: "0px 10px 10px 0px" }}
              onChange={handleChange}
              name="pwd"
              value={newUser.pwd}
            />
          </div>
          <div className="input">
            <label className="label">Landline Number</label>
            <TextField
              required
              variant="filled"
              placeholder="5884569"
              size="small"
              sx={{ padding: "0px 10px 10px 0px" }}
              onChange={handleChange}
              name="land_number"
              value={newUser.land_number}
            />
          </div>
          <div className="input">
            <label className="label">Mobile Number</label>
            <TextField
              required
              variant="filled"
              placeholder="45896426"
              size="small"
              sx={{ padding: "0px 10px 10px 0px" }}
              onChange={handleChange}
              name="mobile_number"
              value={newUser.mobile_number}
            />
          </div>
          <div className="input">
            <label className="label">Whatsapp Number</label>
            <TextField
              required
              variant="filled"
              placeholder="45862145"
              size="small"
              sx={{ padding: "0px 10px 10px 0px" }}
              onChange={handleChange}
              name="whatsapp_number"
              value={newUser.whatsapp_number}
            />
          </div>

          <div className="input">
            <label className="label">Email</label>
            <TextField
              required
              variant="filled"
              placeholder="cnspvtltd@gmail.com"
              size="small"
              sx={{ padding: "0px 10px 10px 0px" }}
              onChange={handleChange}
              name="email"
              value={newUser.email}
            />
          </div>
          <div className="input">
            <label className="label">Contact Person</label>
            <TextField
              required
              variant="filled"
              placeholder="Raju"
              size="small"
              sx={{ padding: "0px 10px 10px 0px" }}
              onChange={handleChange}
              name="contact_person"
              value={newUser.contact_person}
            />
          </div>
          <div className="input">
            <label className="label">Image Quantity</label>
            <TextField
              required
              variant="filled"
              placeholder="200"
              size="small"
              sx={{ padding: "0px 10px 10px 0px" }}
              onChange={handleChange}
              name="image_qty"
              value={newUser.image_qty}
            />
          </div>
          <div className="input">
            <label className="label">Category Quantity</label>
            <TextField
              required
              variant="filled"
              placeholder="max 20"
              size="small"
              sx={{ padding: "0px 10px 10px 0px" }}
              onChange={handleChange}
              name="category_qty"
              value={newUser.category_qty}
            />
          </div>
        </div>
        <div>
          <div className="input">
            <label className="label">Sales Staff</label>
            <TextField
              required
              variant="filled"
              size="small"
              placeholder="Ranjith"
              sx={{ padding: "0px 10px 10px 0px" }}
              onChange={handleChange}
              name="sales_staff"
              value={newUser.sales_staff}
            />
          </div>
          <div className="input">
            <label className="label">Booking Date</label>
            <TextField
              required
              variant="filled"
              placeholder="02/01/2024"
              size="small"
              sx={{ padding: "0px 10px 10px 0px" }}
              onChange={handleChange}
              name="booking_date"
              value={newUser.booking_date}
            />
          </div>
          <div className="input">
            <label className="label">Reference Number</label>
            <TextField
              required
              variant="filled"
              size="small"
              placeholder="520"
              sx={{ padding: "0px 10px 10px 0px" }}
              onChange={handleChange}
              name="reference_number"
              value={newUser.reference_number}
            />
          </div>
          <div className="input">
            <label className="label">Location</label>
            <TextField
              required
              variant="filled"
              size="small"
              placeholder="(12.8,13.8)"
              sx={{ padding: "0px 10px 10px 0px" }}
              onChange={handleChange}
              name="location"
              value={newUser.location}
            />
          </div>
          <div className="input">
            <label className="label">City</label>
            <TextField
              required
              variant="filled"
              size="small"
              placeholder="Chennai"
              sx={{ padding: "0px 10px 10px 0px" }}
              onChange={handleChange}
              name="city"
              value={newUser.city}
            />
          </div>
          <div className="input">
            <label className="label">Address</label>
            <TextField
              required
              variant="filled"
              placeholder="2/3,greens road,vadapalani"
              size="small"
              sx={{ padding: "0px 10px 10px 0px" }}
              onChange={handleChange}
              name="address"
              value={newUser.address}
            />
          </div>
          <div className="input">
            <label className="label">Facebook</label>
            <TextField
              required
              variant="filled"
              placeholder="https://www.facebook.com/SmartProtoX/"
              size="small"
              sx={{ padding: "0px 10px 10px 0px" }}
              onChange={handleChange}
              name="facebook"
              value={newUser.facebook}
            />
          </div>
          <div className="input">
            <label className="label">Twitter</label>
            <TextField
              required
              variant="filled"
              size="small"
              placeholder="https://www.twitter.com/SmartProtoX/"
              sx={{ padding: "0px 10px 10px 0px" }}
              onChange={handleChange}
              name="twitter"
              value={newUser.twitter}
            />
          </div>
          <div className="input">
            <label className="label">Instagram</label>
            <TextField
              required
              variant="filled"
              size="small"
              placeholder="https://www.instagram.com/SmartProtoX/"
              sx={{ padding: "0px 10px 10px 0px" }}
              onChange={handleChange}
              name="instagram"
              value={newUser.instagram}
            />
          </div>
          <div className="input">
            <label className="label">Tiktok</label>
            <TextField
              required
              variant="filled"
              size="small"
              placeholder="https://www.tiktok.com/SmartProtoX/"
              sx={{ padding: "0px 10px 10px 0px" }}
              onChange={handleChange}
              name="tiktok"
              value={newUser.tiktok}
            />
          </div>
        </div>
      </div>
      <Button
        className="add-prod-btn button-primary"
        variant="contained"
        type="button"
        onClick={handleSubmit}
      >
        Submit
      </Button>
    </div>
  );
};

export default Admin;
